import { React } from 'react';
import ResumenReservas from 'components/ResumenReservas';
import "./reservations.css"

const Reservations = () => {
    return (
        <div className='rounded-4 p-3 my-4 color-bg'>
            <ResumenReservas />
        </div>
    );
};

export default Reservations;