import React, { useState, useEffect } from "react";
import "./register.css";
import logo from "assets/images/logo.svg";
import logo_name from "assets/images/logo_name.svg";
import wsp from "assets/icons/rrss/Whatsapp.svg";
import ig from "assets/icons/rrss/Instagram.svg";
import fb from "assets/icons/rrss/Facebook.svg";
import li from "assets/icons/rrss/Linkedin.svg";
import yt from "assets/icons/rrss/Youtube.svg";
import mg from "assets/icons/rrss/Messenger.svg";
import "@fontsource/poppins";
import { registerUser, registerUserGoogle } from "services/authApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const GoogleRegister = () => {
  const location = useLocation();
  const googleUserData = location.state?.googleUserData;

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [user, setUser] = useState('');
  const [pre_phone, setPrePhone] = useState('');
  const [birthday, setBirthday] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Google User Data:', googleUserData);

    if (googleUserData) {
      setEmail(googleUserData.email);
      setName(googleUserData.name);
    }
  }, [googleUserData]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (birthday && dayjs(birthday).isAfter(dayjs(), 'day')) {
      toast.error('La fecha de nacimiento no puede ser futura');
      return;
    }
    const formattedBirthday = birthday ? dayjs(birthday).format('YYYY-MM-DD') : null;

    const userData = {
      sub_id: googleUserData.sub,
      usuario: user,
      email: email,
      contrasena: null,
      tipo: 'Usuario',
      nombre: name,
      telefono: pre_phone,
      fecha_nacimiento: formattedBirthday,
    };
    console.log('User data:', userData);

    try {
      const response = await registerUserGoogle(userData);
      console.log('User registered:', response);
      toast.success('Usuario registrado correctamente');
      navigate('/');
    } catch (error) {
      console.error('Registration failed:', error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className='register-container'>
        <div className='register-content'>
          <div className="register-logo-container">
            <Link to="/"><img src={logo} alt="CourApp Logo" className="register-logo" /> </Link>
            <img src={logo_name} alt="CourApp Logo Name" className="register-logo-name" />
          </div>
          <div className='register-form-container'>
            <div className="register-form">
              <div className="title">Crea tu cuenta en <dest className="dest">CourApp</dest></div>
              <FormControl>
                <form onSubmit={handleSubmit} className='form'>
                  <label className='mb-3' htmlFor="register">Registro con Google</label>
                  <TextField
                    className='form-input mb-2'
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    label="Usuario"
                    variant="outlined"
                    required
                  />
                  <TextField
                    className='form-input mb-2'
                    value={name}
                    label="Nombre"
                    variant="outlined"
                    disabled
                  />
                  <TextField
                    className='form-input mb-2'
                    type='email'
                    value={email}
                    label="Correo Electronico"
                    variant="outlined"
                    disabled
                  />
                  <TextField
                    className='form-input mb-2'
                    value={pre_phone}
                    onChange={(e) => setPrePhone(e.target.value)}
                    label="Telefono Celular (+56)"
                    variant="outlined"
                    required
                  />
                  <DatePicker
                    label="Fecha de Nacimiento"
                    className='w-100'
                    value={birthday}
                    onChange={(newValue) => setBirthday(newValue)}
                    format='DD/MM/YYYY'
                    disableFuture
                    renderInput={(params) => <TextField {...params} helperText="DD/MM/AAAA" required />}
                  />
                  <FormControlLabel className="my-3" control={<Checkbox defaultChecked />} label="Acepto los termino y condiciones" required />
                  <button type="submit" className='register-button'>REGISTRARSE</button>
                </form>
              </FormControl>
            </div>
            <div className="register-links">
              <img src={wsp} alt="Whatsapp" className="social-icon" />
              <img src={ig} alt="Instagram" className="social-icon" />
              <img src={fb} alt="Facebook" className="social-icon" />
              <img src={yt} alt="Youtube" className="social-icon" />
              <img src={mg} alt="Messenger" className="social-icon" />
              <img src={li} alt="Linkedin" className="social-icon" />
            </div>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default GoogleRegister;