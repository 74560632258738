import axios from "axios";

const BASE_API_URL = process.env.REACT_APP_BASE_URL;

/**
 *
 * @returns {Promise<Array>} Recintos
 */
export const fetchRecintos = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=recinto/get`);
    return response.data;
  } catch (error) {
    console.error("Error fetching recintos:", error);
    return [];
  }
};

export const fetchRecintosById = async (id) => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=recinto/get&id=${id}`);
    console.log("respuesta de la api", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching recintos:", error);
    return [];
  }
};

export const fetchRecintosLocation = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=recinto/recintoLocation`);
    return response.data;
  } catch (error) {
    console.error("Error fetching recintos:", error);
    return [];
  }
};

/**
 *
 * @param {String} comuna
 * @returns
 */

export const fetchRecintosByParamComuna = async (comuna) => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=recinto/get`, {
      params: { comuna },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching recintos:", error);
    return [];
  }
};

/**
 *
 * @param {String} comuna
 * @param {Number} valoracion es el promedio de la valoracion del recinto
 * @returns
 */
export const fetchRecintoByParamComunaValoracion = async (comuna, valoracion) => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=recinto/get`, {
      params: { comuna, valoracion_promedio: valoracion },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching recintos:", error);
    return [];
  }
};

export const fetchRecintoByParamComunaTipo = async (comuna, tipo_id, page = 1) => {
  try {
    const params = { page };
    if (comuna) params.comuna = comuna;
    if (tipo_id) params.tipo_id = tipo_id;

    const response = await axios.get(`${BASE_API_URL}?f=recinto/getRecintoByPage`, { params });
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error fetching recintos:", error);
    return null;
  }
};

export const fetchComunasRecintos = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=recinto/get_comunas`);
    return response.data;
  } catch (error) {
    console.error("Error fetching comunas:", error);
    return [];
  }
};

export const fetchRecintoCount = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=recinto/countRecinto`);
    return response.data.total;
  } catch (error) {
    console.error("Error fetching recinto count:", error);
    return 0;
  }
};

/**
 * Fetch valoraciones aprobadas para un recinto dado.
 * @param {number} recintoId - ID del recinto.
 * @returns {Promise<Array>} - Lista de valoraciones aprobadas o un mensaje indicando que no hay datos.
 */
export const fetchValoracionesAprobadas = async (recintoId) => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=recinto/get-valoraciones-aprobadas`, {
      params: { recinto_id: recintoId },
    });

    if (!response.data || response.data.length === 0) {
      throw new Error("No se encontraron valoraciones aprobadas para este recinto");
    }

    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || "Error al cargar las valoraciones";
    throw new Error(errorMessage);
  }
};
