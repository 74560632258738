import Login from 'components/login/login'
import React from 'react'

function LoginPage() {
  return (

    <div className='bg-image-container min-vh-100 ' >
      <Login />
    </div>


  )
}

export default LoginPage