import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL;

export const addFavorito = async (usuarioId, recintoId) => {
  try {
    const response = await axios.post(
      `${API_URL}favorito/insert`,
      new URLSearchParams({
        usuario_id: usuarioId,
        recinto_id: recintoId,
      }).toString(),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al agregar el favorito:", error);
    throw error;
  }
};

export const removeFavorito = async (usuarioId, recintoId) => {
  try {
    const response = await axios.post(
      `${API_URL}?f=favorito/delete`,
      new URLSearchParams({
        usuario_id: usuarioId,
        recinto_id: recintoId,
      }).toString(),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al eliminar el favorito:", error);
    throw error;
  }
};

export const isFavorito = async (usuarioId, recintoId) => {
  try {
    const response = await axios.get(`${API_URL}?f=favorito/check`, {
      params: { usuario_id: usuarioId, recinto_id: recintoId },
    });
    return response.data;
  } catch (error) {
    console.error("Error al verificar el favorito:", error);
    throw error;
  }
};

export const fetchFavoriteReservas = async (usuarioId) => {
  try {
    const response = await axios.get(`${API_URL}?f=favorito/get`, {
      params: { usuario_id: usuarioId },
    });
    return response.data;
  } catch (error) {
    console.error("Error al obtener las reservas favoritas:", error);
    throw error;
  }
};

export const getFavoritoData = async (usuarioId) => {
  try {
    const response = await axios.get(`${API_URL}?f=favorito/getData`, {
      params: { usuario_id: usuarioId },
    });
    return response.data;
  } catch (error) {
    console.error("Error al obtener los datos completos de los favoritos:", error);
    throw error;
  }
};
