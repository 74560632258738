import React, { useEffect, useState } from "react";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { useMapStore } from './store';
import PoiMarkers from "./PoiMarkers"
import { fetchRecintosLocation } from "services/recintoApi";

const CourtLocation = () => {
  const [comunas, setComunas] = useState([]);
  const [selectedComuna, setSelectedComuna] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { lugares, setLugares, filteredLugares, setFilteredLugares, setSelectedLugar } = useMapStore();

  useEffect(() => {
    const getRecintos = async () => {
      setLoading(true);
      try {
        const data = await fetchRecintosLocation();
        const formattedData = data.map((recinto) => ({
          key: recinto.id,
          nombre: recinto.nombre,
          comuna: recinto.comuna,
          location: {
            lat: parseFloat(recinto.geo_y),
            lng: parseFloat(recinto.geo_x),
          },
        }));
        setLugares(formattedData);

        const uniqueComunas = [...new Set(formattedData.map((item) => item.comuna))];
        setComunas(uniqueComunas);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    getRecintos();
  }, [setLugares]);

  useEffect(() => {
    if (selectedComuna) {
      const filtered = lugares.filter((lugar) => lugar.comuna === selectedComuna);
      setFilteredLugares(filtered);
    } else {
      setFilteredLugares([]);
    }
  }, [selectedComuna, lugares, setFilteredLugares]);

  const handleLugarClick = (lugar) => {
    setSelectedLugar(lugar);
  };

  return (
    <>
      <div className="bg-light">
        <div className="container p-3">
          <div className="row mb-2 gap-3">
            <div className="col-sm-12">
              <h3>Recintos cercanos</h3>
            </div>
            <div className="col-lg-4 col-12">
              <Autocomplete
                className="w-100"
                disablePortal
                id="combo-box-comunas"
                options={comunas}
                value={selectedComuna}
                noOptionsText="No hay comunas"
                onChange={(event, newValue) => setSelectedComuna(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Comunas"
                    error={error && !selectedComuna}
                    helperText={error && !selectedComuna ? "Selecciona una comuna" : ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div className="col-lg-4 col-12">
              {filteredLugares.length > 0 && (
                <Autocomplete
                  className="w-100"
                  disablePortal
                  id="combo-box-recintos"
                  options={filteredLugares}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(event, newValue) => handleLugarClick(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Recintos"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="vh-100 w-100">
        <APIProvider apiKey={"AIzaSyDwLM50e6GVs7zNFQNk2fmettCJSO2IDXU"}  libraries={['marker']}>
          <Map defaultZoom={13} defaultCenter={{ lat: -36.827909, lng: -73.051539 }} mapId="cbe108b482e65e9c">
            <PoiMarkers />
          </Map>
        </APIProvider>
      </div>
    </>
  );
};

export default CourtLocation;
