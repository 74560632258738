import React, { useCallback, useEffect, useState } from 'react';
import './navbar.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ReservationIcon } from 'assets/icons/navbar-images/reserv.svg';
import { ReactComponent as PlacesIcon } from 'assets/icons/navbar-images/location.svg';
import { ReactComponent as CourtIcon } from 'assets/icons/navbar-images/canchas.svg';
import img from 'assets/images/user.png';
import fullLogo from 'assets/images/logo+name.webp';
import ballLogo from 'assets/images/ball-logo.webp';
import search from 'assets/icons/navbar-images/search.svg';
import { Autocomplete, TextField } from '@mui/material';
import { fetchComunasRecintos } from 'services/recintoApi';
import { getTokenFromLocalStorage } from 'services/authApi';
import { useAuth } from 'hooks/authProvider';
import { useUserStore } from 'store';

function Navbar() {
  const auth = useAuth();
  const userName = useUserStore((state) => state.user?.data?.nombre);
  const userImage = useUserStore((state) => state.user?.data?.image_url);
  const [state, setState] = useState({
    comunas: [],
    selectedComuna: "",
    error: false,
    userName: ""
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await getTokenFromLocalStorage();
      const comunasData = await fetchComunasRecintos();
      setState(prev => ({
        ...prev,
        comunas: comunasData.map(comuna => comuna.comuna), 
        userName
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!state.selectedComuna) {
      setState(prev => ({ ...prev, error: true }));
    } else {
      setState(prev => ({ ...prev, error: false }));
      navigate(`/places?comuna=${state.selectedComuna}`);
    }
  };

  const isActive = useCallback((path) => {
    return new RegExp(`^${path}(/|$)`).test(location.pathname);
  }, [location.pathname]);

  return (
    <nav className="navbar navbar-expand-lg py-3 bg-white">
      <div className="container-md d-flex justify-content-between align-items-center">
        <Link to="/home" className="navbar-brand">
          <img src={fullLogo} alt="CourApp Logo" className="navbar-logo d-none d-md-block" />
          <img src={ballLogo} alt="CourApp Logo" className="navbar-logo d-block d-md-none" />
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 custom-navbar gap-2">
            {['/search', '/reservations', '/court-location'].map((path, index) => (
              <li className="nav-item" key={index}>
                <Link to={path} className={`nav-link py-2 px-3 d-flex align-items-center justify-content-center rounded-5 gap-2 ${isActive(path) ? 'active' : ''}`}>
                  {path === '/search' && <PlacesIcon />}
                  {path === '/reservations' && <ReservationIcon />}
                  {path === '/court-location' && <CourtIcon />}
                  <span className="d-block d-md-inline d-lg-none d-xl-inline">{
                    path === '/search'
                      ? 'Recintos'
                      : path === '/reservations'
                        ? 'Reservas'
                        : 'Canchas'
                  }</span>
                </Link>
              </li>
            ))}
          </ul>
          <div className="d-flex flex-column flex-lg-row align-items-center gap-2">
            <form className="search-bar mb-2 mb-lg-0 d-flex align-items-center gap-1" onSubmit={handleSubmit}>
              <Autocomplete
                disablePortal
                size="small"
                id="combo-box-comunas"
                options={state.comunas}
                className="search-bar-autocomplete-navbar border-0"
                value={state.selectedComuna}
                onChange={(event, newValue) => setState(prev => ({ ...prev, selectedComuna: newValue }))}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}

                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Buscar..."
                    error={state.error && !state.selectedComuna}
                    helperText={state.error && !state.selectedComuna ? "Selecciona una comuna" : ""}
                  />
                )}
              />

              <button type="submit" className="btn-arrow border-0 p-2 rounded-5">
                <img src={search} alt="Search" />
              </button>
            </form>

            <div className="dropdown">
              <button className="btn btn-white dropdown-toggle nav-user rounded px-0 border-0 d-flex align-items-center gap-2" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={userImage || img} alt={userName} className="user-image rounded" />
                <div className="user-name-wrapper">
                   <span className="user-name">{userName || "Usuario"}</span>
                </div>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <Link className="dropdown-item" to="/profile">Perfil</Link>
                <Link className="dropdown-item" to="/favorite">Favoritos</Link>
                <button className="dropdown-item" onClick={() => auth.logOut()} >Cerrar Sesión</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
