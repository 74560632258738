import axios from 'axios';


export const uploadImage = async (file) => {
  try {
    const base64String = await readFileAsBase64(file);
    console.log('base 64:',base64String)
    const extension = file.name.split('.').pop().toLowerCase();

    const response = await axios.post(
      'https://appso.cl/polosur/api.php?f=base64_archivo',
      {
        content: base64String,
        extension: extension,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error al subir el archivo:', error);
    throw error;
  }
};

const readFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    console.log('reader: ',reader)
    reader.onloadend = () => {
      const base64String = reader.result.split(',')[1];
      resolve(base64String);
    };
    reader.onerror = () => {
      reject(new Error('Error al leer el archivo'));
    };
    reader.readAsDataURL(file);
  });
};
