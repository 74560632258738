import React, { useEffect, useRef, useState } from "react";
import VC from "vanilla-calendar-pro";

function VanillaCalendar({ config, availableDates, onSelectDate, ...attributes }) {
  const ref = useRef(null);
  const [calendar, setCalendar] = useState(null);

useEffect(() => {
  const isValidDate = (date) => !isNaN(new Date(date).getTime());

  if (ref.current) {
    if (Array.isArray(availableDates) && availableDates.length > 0 && availableDates.every(isValidDate)) {
      const minDate = availableDates[0];
      const maxDate = availableDates[availableDates.length - 1];

      const calendarConfig = {
        settings: {
          lang: 'es',
          visibility: {
            theme: 'light',
          },
        },
        date: {
          min: minDate,
          max: maxDate,
          selected: availableDates[0], 
        },
        dates: {
          enabled: availableDates.map((date) => ({ date })),
        },
        actions: {
          clickDay: (event, date) => {
            onSelectDate(date);
          },
        },
        ...config,
      };

      if (!calendar) {
        const cal = new VC(ref.current, calendarConfig);
        cal.init();
        setCalendar(cal);
      } else {
        calendar.update({
          date: {
            min: minDate,
            max: maxDate,
            selected: availableDates[0],
          },
          dates: {
            enabled: availableDates.map((date) => ({ date })),
          },
        });
      }
    } else {
      if (calendar) {
        calendar.destroy();
        setCalendar(null);
      }
    }
  }
}, [availableDates, config, calendar, onSelectDate]);

  useEffect(() => {
    return () => {
      if (calendar) {
        calendar.destroy();
      }
    };
  }, [calendar]);

  return (
    <div className="w-100 border" {...attributes} ref={ref}>
      {Array.isArray(availableDates) && availableDates.length > 0 ? (
        <div className="w-100 border" />
      ) : (
        <div className="nonselected-msg d-flex justify-content-center align-items-center border-0" style={{ height: '100px' }}>
          <p className="text-center">No hay fechas disponibles</p>
        </div>
      )}
    </div>
  );
}

export default VanillaCalendar;
