import React from 'react'
import NavBar from "../../shared/components/navbar/navbar"
import Footer from "../../shared/components/footer/footer"
import DetailsBooking from 'components/detailsBooking/detailsBooking'

function BookingDetailPage() {
  return (
    <div className="d-flex flex-column min-vh-100">
      <NavBar />
      <div className='flex-fill bg-image-container'>
        <DetailsBooking></DetailsBooking>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default BookingDetailPage