import Register from 'components/register/register'
import React from 'react'

function RegisterPage() {
  return (
    <div className='bg-image-container min-vh-100'>
      <Register />
    </div>
  )
}

export default RegisterPage