import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Calendar from "components/calendar/calendar";
import CourtPage from "pages/court/CourtPage";
import DetailsBooking from "pages/bookingDetails/BookingDetailPage";
import Home from "pages/home/Home";
import PlacesPage from "pages/places/PlacesPage";
import Profile from "pages/profile/profile";
import React, { useLayoutEffect } from "react";
import Search from "pages/search/searchPage";
import LoginPage from "pages/login/Login";
import RegisterPage from "pages/register/Register";
import ReservationsPage from "pages/reservations/reservations";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "vanilla-calendar-pro/build/vanilla-calendar.min.css";
import CourtLocationPage from "pages/courtLocation/CourtLocationPage";
import GoogleRegister from "components/register/GoogleRegister";
import AuthProvider from "hooks/authProvider";
import PrivateRoute from "router/privateRoute";
import FavoritePage from "pages/favorite/favorite";
import CheckoutPage from "pages/checkout/CheckoutPage";
import ForgotPage from "pages/forgot-password/ForgotPassword";

function App() {
  useLayoutEffect(() => {}, []);
  return (
    <div className="">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/google-register" element={<GoogleRegister />} />
            <Route path="/forgot-password" element={<ForgotPage />} />
            <Route element={<PrivateRoute />}>
              <Route path="/home" element={<Home />} />
              <Route path="/places" element={<PlacesPage />} />
              <Route path="/search" element={<Search />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/detail-booking" element={<DetailsBooking />} />
              <Route path="/court" element={<CourtPage></CourtPage>} />
              <Route path="/checkout/:id" element={<CheckoutPage />} />
              <Route path="/court-location" element={<CourtLocationPage />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/favorite" element={<FavoritePage />} />
              <Route path="/reservations" element={<ReservationsPage />} />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
      <div id="modal-root"></div>
    </div>
  );
}

export default App;
