import React, { useState } from "react";
import "./login.css";
import loginLogo from "assets/images/courapp-login.png";
import wsp from "assets/icons/rrss/Whatsapp.svg";
import ig from "assets/icons/rrss/Instagram.svg";
import fb from "assets/icons/rrss/Facebook.svg";
import li from "assets/icons/rrss/Linkedin.svg";
import yt from "assets/icons/rrss/Youtube.svg";
import mg from "assets/icons/rrss/Messenger.svg";
import "@fontsource/poppins";
import log_fb from "assets/icons/rrss/Login.with.facebook.svg";
import { loginUser } from "services/authApi";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import { loginUserWithGoogle } from "services/googleAuthApi";
import { jwtDecode } from "jwt-decode";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import KeyIcon from "@mui/icons-material/Key";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAuth } from "hooks/authProvider";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const auth = useAuth();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    const token = credentialResponse.credential;
    try {
      const verifyResponse = await loginUserWithGoogle(token);

      if (verifyResponse && verifyResponse.exists) {
        if (verifyResponse.jwtToken) {
          await auth.googleLoginValidator(verifyResponse.jwtToken);
          toast.success("Inicio de sesión exitoso");
          navigate("/home");
        } else {
          console.error("JWT token missing from backend response");
          toast.error("Error: No se recibió el token JWT. Contacte al soporte.");
        }
      } else {
        const decoded = jwtDecode(token);
        toast.info("Usuario no registrado, por favor completa el registro");
        navigate("/register", { state: { googleUserData: decoded } });
      }
    } catch (error) {
      console.error("Error verifying sub_id:", error);
      toast.error("Error al verificar el usuario");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email !== "" && password !== "") {
      try {
        const response = await auth.loginAction({ credentials: { email, contrasena: password } });
        if (response) {
          toast.success("Inicio de sesión exitoso");
          navigate("/home");
        }
      } catch (error) {
        console.error("Error en el inicio de sesión:", error);
        toast.error("Error al iniciar sesión, verifica tus credenciales");
      }
    } else {
      toast.error("Por favor, rellena todos los campos");
    }
  };

  return (
    <div className="login-container min-vh-100 d-flex justify-content-center align-items-center">
      <div className="container container-login">
        <div className="row">
          <div className="col-lg-12 text-center">
            <img src={loginLogo} alt="Logo" className="login-logo mb-3" />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 text-center">{/* Espacio reservado */}</div>
          <div className="col-2">{/* Espacio reservado */}</div>
          <div className="col-sm-8">
            <div className="card p-4 mb-2">
              <div className="title mb-2">
                Bienvenido a <span className="dest-user">CourApp</span>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="w-100 my-2">
                  {/* <img src={log_fb} alt="Login.fb" className="log_rrss" /> */}
                  <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onError={() => {
                      console.log("Login Failed");
                      toast.error("Error al iniciar sesión con Google");
                    }}
                    size="large"
                    width={325}
                  />
                </div>

                <div className="container-fluid px-0 mb-2 d-flex align-items-center">
                  <hr className="flex-grow-1" />
                  <span className="mx-3 d-flex align-items-center fs-custom">Accesos de Usuarios Registrados</span>
                  <hr className="flex-grow-1" />
                </div>

                <div className="bg-light border py-1 px-2 mb-3 rounded">
                  <Box sx={{ display: "flex", alignItems: "flex-end" }} className="mb-3">
                    <MailIcon sx={{ mr: 1.5 }} />
                    <TextField fullWidth label="Correo" value={email} onChange={(e) => setEmail(e.target.value)} required type="email" variant="standard" className="custom-filled-button" />
                  </Box>
                </div>
                <div className="bg-light border py-1 px-2 mb-3 rounded">
                  <Box sx={{ display: "flex", alignItems: "flex-end" }} className="mb-3">
                    <KeyIcon sx={{ mr: 1.5, my: 0.5 }} />
                    <TextField
                      fullWidth
                      label="Contraseña"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      variant="standard"
                      className="custom-filled-button"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton sx={{ mr: 0 }} aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </div>
                <div className="row mx-1 mb-2">
                  <div className="col-sm-4 px-0">
                    <input type="checkbox" /> <small>Recordar</small>
                  </div>
                  <div className="col-sm-8 text-end px-0">
                    <Link className="forget" to="/forgot-password">
                      <small>¿Olvidaste tu contraseña?</small>
                    </Link>
                  </div>
                </div>
                <button className="btn btn-primary w-100">Iniciar Sesión</button>
                <div className="row mx-1 mt-3">
                  <div className="col-sm-12 text-center">
                    <small>
                      ¿No tienes una cuenta?
                      <Link to="/register" className="registro_link ms-2">
                        Regístrate
                      </Link>
                    </small>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-sm-2 col-12 py-sm-3">
            <div className="login-links d-flex flex-row flex-sm-column gap-3">
            <a href="https://api.whatsapp.com/send?phone=56966109061" target="_blank" rel="noopener noreferrer">
                <img src={wsp} alt="Whatsapp" className="social-icon" />
              </a>
              <a href="https://www.instagram.com/courapp_chile/profilecard/" target="_blank" rel="noopener noreferrer">
                <img src={ig} alt="Instagram" className="social-icon" />
              </a>
              {/* <img src={fb} alt="Facebook" className="social-icon" /> */}
              {/* <img src={yt} alt="Youtube" className="social-icon" /> */}
              {/* <img src={mg} alt="Messenger" className="social-icon" /> */}
              {/* <img src={li} alt="Linkedin" className="social-icon" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
