import React from 'react';
import NavBar from '../../shared/components/navbar/navbar';
// import Footer from '../../shared/components/footer/footer';
import CourtLocation from 'components/courtLocation/CourtLocation';
import Footer from 'shared/components/footer/footer';

function CourtLocationPage() {
  console.log('court component loaded');
  return (
    <>
      <NavBar />
      <div className='bg-image-container'>
      <div className=''>
        <CourtLocation />
      </div>
        </div>
       <Footer /> 
    </>
  );
}

export default CourtLocationPage;
