import axios from "axios";

const BASE_API_URL = process.env.REACT_APP_BASE_URL;

export const createValoracion = async (valoracionData) => {
  try {
    console.log("valoracionData", valoracionData);
    const response = await axios.post(`${BASE_API_URL}?f=valoracion/insertValidada`, new URLSearchParams(valoracionData).toString(), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating valoracion:", error.response.data.message);
    throw error;
  }
};
