import { create } from 'zustand';;

export const useMapStore = create((set) => ({
  lugares: [],
  setLugares: (lugares) => set({ lugares }),
  selectedLugar: null,
  setSelectedLugar: (lugar) => set({ selectedLugar: lugar }),
  filteredLugares: [],
  setFilteredLugares: (filteredLugares) => set({ filteredLugares }),
}));
