import React, { useEffect, useState, useRef } from "react";
import canchasPlaceholder from "assets/images/canchasplaceholder.jpg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { fetchInstalacionesByRecintoId } from "services/instalacionApi";
import { fetchAvailableReservations } from "services/reservaApi";
import { useNavigate, useLocation } from "react-router-dom";
import "./court.css";
import CourtBooking from "./component/CourtBooking";
import { useUserStore } from "store";
import { fetchRecintosById } from "services/recintoApi";
import MapComponent from "./component/MapComponent";
import { APIProvider } from "@vis.gl/react-google-maps";
import { fetchTiposInstalacion } from "services/tipoInstalacionApi";
import { formatCurrencyCLP } from "utils/format";
import Rating from "components/ratings/Rating";

const Courts = () => {
  const [installationSelected, setInstallationSelected] = useState(false);
  const [instalaciones, setInstalaciones] = useState([]);
  const [selectedInstalacion, setSelectedInstalacion] = useState(null);
  const [selectedInstalacionData, setSelectedInstalacionData] = useState(null);
  const [selectedInstallationId, setSelectedInstallationId] = useState(null);
  const [dates, setDates] = useState([]);
  const [reservas, setReservas] = useState([]);
  const [tiposInstalacion, setTiposInstalacion] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const usuarioId = useUserStore((state) => state.user?.data?.id);
  const [recintoNombre, setRecintoNombre] = useState("");
  const [geo_x, setGeo_x] = useState("");
  const [geo_y, setGeo_y] = useState("");

  const datesRef = useRef(null);

  const searchParams = new URLSearchParams(location.search);
  const recintoId = searchParams.get("id");
  const instalacionIdParam = searchParams.get("instalacion_id");

  const handleSelectInstallation = (id) => {
    setSelectedInstalacion(id);
    setInstallationSelected(false);
    setSelectedInstallationId(id);
    setSelectedInstalacionData(null);
    setDates([]);
    setReservas([]);
    fetchDates(id).then(() => setInstallationSelected(true));

    navigate(
      {
        pathname: location.pathname,
        search: `?id=${recintoId}&instalacion_id=${id}`,
      },
      { replace: true }
    );
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const fetchDates = async (instalacionId) => {
    try {
      const currentDate = new Date().toISOString().split("T")[0];
      const availableReservations = await fetchAvailableReservations(instalacionId, currentDate);
      const availableDates = Object.keys(availableReservations);
      setDates(availableDates);
    } catch (error) {
      console.log("Error fetching available dates:", error);
    }
  };

  useEffect(() => {
    if (installationSelected) {
      const timer = setTimeout(() => {
        if (datesRef.current) {
          datesRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
        } else {
          console.error("El elemento 'dates' no se encontró en el DOM.");
        }
      }, 150);

      return () => clearTimeout(timer);
    }
  }, [installationSelected]);

  useEffect(() => {
    if (!recintoId) {
      navigate("/home");
    }
  }, [recintoId, navigate]);

  useEffect(() => {
    const fetchInstalacion = async () => {
      try {
        const response = await fetchRecintosById(recintoId);
        setRecintoNombre(response.nombre);
        setGeo_x(response.geo_x);
        setGeo_y(response.geo_y);
        setImageUrl(response.image_url);

        const instalaciones = await fetchInstalacionesByRecintoId(recintoId);
        setInstalaciones(instalaciones);

        if (instalaciones.length > 0 && instalacionIdParam) {
          handleSelectInstallation(instalacionIdParam);
        }
      } catch (error) {
        console.log("Error fetching instalaciones:", error);
      }
    };
    fetchInstalacion();
  }, [recintoId]);

  useEffect(() => {
    const fetchTipos = async () => {
      const tipos = await fetchTiposInstalacion();
      const tiposById = tipos.reduce((acc, tipo) => {
        acc[tipo.id] = tipo.nombre;
        return acc;
      }, {});
      setTiposInstalacion(tiposById);
    };
    fetchTipos();
  }, []);

  useEffect(() => {
    const reloadTimeout = setTimeout(() => {
      window.location.reload();
    }, 300000);
    return () => clearTimeout(reloadTimeout);
  }, []);

  const processedInstalaciones = instalaciones.map((instalacion) => {
    try {
      return {
        ...instalacion,
        imageUrls: instalacion.image_url ? JSON.parse(instalacion.image_url) : [],
      };
    } catch (e) {
      console.error("Error parsing image URLs for instalacion", instalacion.id, e);
      return {
        ...instalacion,
        imageUrls: [],
      };
    }
  });

  return (
    <div className="courts container-md px-0">
      <div className="court-container rounded-4 p-4 my-4 mx-2">
        <div className="row d-flex justify-content-center">
          <div className="col-12">
            <h2 className="text-start fw-bold ">{recintoNombre ?? "Detalles del Recinto"}</h2>
          </div>
          <div className="col-lg-6 col-md-12 my-2">
            <APIProvider apiKey="AIzaSyDwLM50e6GVs7zNFQNk2fmettCJSO2IDXU">
              <MapComponent geo_x={geo_x} geo_y={geo_y} />
            </APIProvider>
          </div>
          <div className="col-lg-6 col-md-12 my-2">
            <div className="image-container">
              <img src={imageUrl || canchasPlaceholder} alt={recintoNombre || "Imagen del Recinto"} className="court-splider-image" />
            </div>
          </div>

          <div className="col-12">
            <hr className="color-hr" />
            <h4 className="text-start">Elige tu cancha</h4>
          </div>
          <div className="col-12 text-center my-2">
            {processedInstalaciones.length === 0 ? (
              <p className="fw-bold ">No hay instalaciones disponibles en este recinto.</p>
            ) : (
              <div className="row row-cols-1 row-cols-md-3 g-4">
                {processedInstalaciones.map((instalacion) => (
                  <div className="col d-flex" key={instalacion.id}>
                    <div className="card border-0 t-maximo">
                      {instalacion.imageUrls.length > 1 ? (
                        <Splide
                          options={{
                            type: "loop",
                            gap: "1rem",
                            autoplay: true,
                            pauseOnHover: true,
                            resetProgress: false,
                            perPage: 1,
                            breakpoints: {
                              1024: { perPage: 1 },
                              768: { perPage: 1 },
                            },
                          }}
                        >
                          {instalacion.imageUrls.map((url, index) => (
                            <SplideSlide key={index}>
                              <img src={url} alt={`Imagen ${index + 1} de ${instalacion.nombre}`} className="court-splider-image card-img-top object-cover object-center" />
                            </SplideSlide>
                          ))}
                        </Splide>
                      ) : (
                        <img src={instalacion.imageUrls.length === 1 ? instalacion.imageUrls[0] : canchasPlaceholder} alt={instalacion.nombre} className="court-splider-image card-img-top" />
                      )}
                      <div className="card-body py-4 flex-fill">
                        <h5 className="card-title">{instalacion.nombre}</h5>
                        <p className="card-text text-muted mb-0">
                          <strong> Tipo de cancha: </strong> {tiposInstalacion[instalacion.tipo_id] || "Tipo no disponible"}
                        </p>
                        <p className="card-text text-muted mb-2">
                          <strong> Descripción:</strong> {instalacion.descripcion}
                        </p>
                        <p className="card-text">
                          Por hora: <strong>{formatCurrencyCLP(instalacion.valor_unitario)}</strong>
                        </p>
                        <p className="card-text">
                          Reserva con el 25%: <strong>{formatCurrencyCLP(instalacion.valor_unitario * 0.25)}</strong>
                        </p>
                      </div>
                      <div className="card-footer card-footer-custom my-2 px-0">
                        <button type="button" className={`btn-reservar rounded-5 py-2 w-100 ${selectedInstallationId === instalacion.id ? "btn-reservar-active" : ""}`} onClick={() => handleSelectInstallation(instalacion.id)}>
                          Elegir
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {installationSelected && (
            <CourtBooking
              selectedInstalacion={selectedInstalacion}
              selectedInstalacionData={selectedInstalacionData}
              setSelectedInstalacionData={setSelectedInstalacionData}
              selectedInstallationId={selectedInstallationId}
              dates={dates}
              setDates={setDates}
              reservas={reservas}
              setReservas={setReservas}
              usuarioId={usuarioId}
              handleGoBack={handleGoBack}
            />
          )}
          <div id="dates" ref={datesRef}></div>
        </div>
      </div>

      <div className="rounded-4 py-4 my-4 mx-4">
        <h3 className="ultimas-valoraciones-title badge rounded-4 fs-3 badge-color" >Últimas valoralizaciones realizadas</h3>
        <Rating recintoId={recintoId} />
      </div>
    </div>
  );
};

export default Courts;
