import React, { useState, useEffect } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { fetchValoracionesAprobadas } from "services/recintoApi";
import { Modal } from "react-bootstrap";
import StarRating from "components/court/star";
import img from "../../assets/images/user.png";
import "./ratings.css";

const RatingCarousel = ({ recintoId }) => {
  const [valoraciones, setValoraciones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    const loadValoraciones = async () => {
      setLoading(true);

      try {
        const data = await fetchValoracionesAprobadas(recintoId);
        setValoraciones(data || []); // Asegura que siempre sea un array
      } catch {
        setValoraciones([]); // En caso de error, trata como sin datos
      } finally {
        setLoading(false);
      }
    };

    loadValoraciones();
  }, [recintoId]);

  const handleShowModal = (valoracion) => {
    setModalData(valoracion);
  };

  const handleCloseModal = () => {
    setModalData(null);
  };

  // Muestra un mensaje mientras se cargan los datos
  if (loading) {
    return (
      <div className="rating-container rounded-4 p-4 my-4 mx-2">
        <p className="fw-bold text-center my-0">Cargando valoraciones...</p>
      </div>
    );
  }

  // Muestra un mensaje si no hay valoraciones
  if (!valoraciones.length) {
    return (
      <div className="rating-container rounded-4 p-4 my-4 mx-2">
        <p className="fw-bold text-center my-0">No se encontraron valoraciones para este recinto.</p>
      </div>
    );
  }

  // Solo muestra el carrusel cuando hay valoraciones
  return (
    <>
      <Splide
        options={{
          type: "slide",
          gap: "1rem",
          perPage: 3,
          autoplay: false,
          pauseOnHover: true,
          pagination: false,
          breakpoints: {
            768: { perPage: 1 },
            1024: { perPage: 2 },
          },
        }}
        className="mt-4 rating-carousel"
      >
        {valoraciones.map((valoracion) => (
          <SplideSlide key={valoracion.valoracion_id}>
            <div className="card shadow-sm rounded-4 h-100 d-flex flex-column justify-content-between p-5">
              <div>
                <div className="row align-items-center">
                  <div className="col-4 d-flex justify-content-start">
                    <img
                      src={valoracion.usuario_imagen || img}
                      alt={valoracion.usuario_nombre}
                      className="rounded-circle"
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                        border: "2px solid #ccc",
                      }}
                    />
                  </div>
                  <div className="col-8 text-start">
                    <h5 className="mb-1 fw-bold">{valoracion.usuario_nombre}</h5>
                    <p className="text-truncate mb-1">{valoracion.ResenaResumen}</p>
                    <button className="btn btn-link fw-bold p-0" onClick={() => handleShowModal(valoracion)}>
                      Leer reseña...
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-4 d-flex align-items-center">
                <StarRating rating={parseFloat(valoracion.Valoracion)} mode="view" />
                <span className="ms-2">{valoracion.Valoracion}</span>
              </div>
            </div>
          </SplideSlide>
        ))}
      </Splide>

      {/* Modal */}
      {modalData && (
        <Modal show onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Reseña de {modalData.usuario_nombre}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center mb-3">
              <img
                src={modalData.usuario_imagen || img}
                alt={modalData.usuario_nombre}
                className="rounded-circle me-3"
                style={{
                  width: "60px",
                  height: "60px",
                  objectFit: "cover",
                  border: "2px solid #ccc",
                }}
              />
              <div>
                <h5 className="mb-1">{modalData.usuario_nombre}</h5>
              </div>
            </div>
            <p className="text-secondary">{modalData.Resena}</p>
            <div className="col-12 mt-4 d-flex align-items-center">
              <StarRating rating={parseFloat(modalData.Valoracion)} mode="view" />
              <span className="ms-2">{modalData.Valoracion}</span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn-cargar rounded-5 px-5 py-2 text-light" onClick={handleCloseModal}>
              Cerrar
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default RatingCarousel;
