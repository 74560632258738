import { create } from "zustand";
import { devtools } from "zustand/middleware";

// export const useReservationStore = create((set) => ({
//   reservationData: null,
//   setReservationData: (data) => set({ reservationData: data }),
//   clearReservationData: () => set({ reservationData: null }),
// }));

export const useReservationStore = create(
  devtools(
    (set) => ({
      reservationData: null,
      setReservationData: (data) => set({ reservationData: data }),
      clearReservationData: () => set({ reservationData: null }),
    }),
  )
);
