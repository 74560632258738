import React from 'react'
import NavBar from "../../shared/components/navbar/navbar"
import Footer from "../../shared/components/footer/footer"
import Favorite from 'components/favorite/favorite'

function FavoritePage() {

  return (
    
    <div className="d-flex flex-column min-vh-100">
    <NavBar />
    <div className='flex-fill bg-image-container'>
      <Favorite/>
    </div>
    <Footer></Footer>
    </div>
  )
}

export default FavoritePage
