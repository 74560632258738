import React from 'react'

import Places from "components/places/places"
import Navbar from 'shared/components/navbar/navbar';
import Footer from 'shared/components/footer/footer';


function PlacesPage() {
  
  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar />
      <div className='flex-fill bg-image-container'>
        <Places></Places>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default PlacesPage