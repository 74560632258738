import React from 'react';
import ReactDOM from 'react-dom';
// import '../modals/modalsReserva.css';
import './detailsBooking.css';

const ModalConfirmCancel = ({ isOpen, onClose, onConfirm, isLoading }) => {
  if (!isOpen) {
    return null;
  }

  const handleConfirm = () => {
    onConfirm(); 
  };

  return ReactDOM.createPortal(
    <>
      <div className="modal-backdrop show"></div>
      <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1">
        <div className="modal-dialog modal-lg " role="document">
          <div className="modal-content">
            <div className="modal-header sin-border">
              <h3 className="modal-title fw-bold ">Confirmar Cancelación</h3>
            </div>
            <div className="modal-body">
              <p className='modal-text'>
                {/* ¿Estás seguro de que deseas cancelar esta reserva? Esta acción no se puede deshacer. */}

                Actualmente no se puede cancelar la reserva automaticamente. <br /> Por favor, contacte con soporte para cancelar la reserva y reembolso.
              </p>
            </div>
            <div className="modal-footer d-flex justify-content-between sin-border">
              <button type="button" className="btn btn-secondary rounded-5 px-5 py-2 mt-auto" onClick={onClose} disabled={isLoading}>
                Volver
              </button>
              <button 
                type="button" 
                className="btn btn-danger rounded-5 px-5 py-2 mt-auto"
                onClick={handleConfirm}
                disabled={isLoading}
              >
                {isLoading ? (
                  "Cancelando..."
                ) : (
                  "Sí, cancelar"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById('modal-root')
  );
};

export default ModalConfirmCancel;
