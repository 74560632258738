import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./profile.css";
import img from "../../assets/images/user.png";
import Skeleton from "@mui/material/Skeleton";
import PictureUpload from "./PictureUpload";
import { getUsuario, updateUsuario } from "services/usuarioApi";
import { useUserStore } from "store";
import { jwtDecode } from "jwt-decode";
import { uploadImage } from "services/uploadImageServiceApi";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Profile = () => {
  const userId = useUserStore((state) => state.user?.data?.id) || "";
  const userName = useUserStore((state) => state.user?.data?.nombre) || "";
  const userEmail = useUserStore((state) => state.user?.data?.email) || "";
  const userPhone = useUserStore((state) => state.user?.data?.telefono) || "";
  const userBirthdate = useUserStore((state) => state.user?.data?.fecha_nacimiento) || "";
  const userType = useUserStore((state) => state.user?.data?.tipo) || "";
  const userImage = useUserStore((state) => state.user?.data?.image_url) || img;
  const updateUser = useUserStore((state) => state.updateUser);

  const [usuario, setUsuario] = useState({
    id: userId,
    nombre: userName,
    email: userEmail,
    telefono: userPhone,
    fecha_nacimiento: userBirthdate,
    tipo: userType,
  });

  const [formData, setFormData] = useState({
    nombre: userName || "",
    fecha_nacimiento: userBirthdate || "",
    email: userEmail || "",
    telefono: userPhone || "",
  });

  const [originalUsuario, setOriginalUsuario] = useState({});
  const [loading, setLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      // Mostrar los campos de contraseña solo si no existe decoded.data.sub_id
      setShowPasswordFields(!decoded.data?.sub_id);
    }
  }, []);
  useEffect(() => {
    const fetchPerfil = async () => {
      if (!userId) return;
      try {
        const perfilData = await getUsuario(userId);
        setUsuario(perfilData);
        setOriginalUsuario(perfilData);
        setFormData({
          nombre: perfilData.nombre,
          fecha_nacimiento: perfilData.fecha_nacimiento,
          email: perfilData.email,
          telefono: perfilData.telefono,
          password: "",
          confirmPassword: "",
        });
        setLoading(false);
      } catch (err) {
        console.error("Error fetching profile:", err);
        setLoading(false);
      }
    };

    if (userId) {
      fetchPerfil();
    }
  }, [userId]);

  useEffect(() => {
    setIsChanged(
      JSON.stringify(formData) !==
        JSON.stringify({
          nombre: originalUsuario.nombre,
          fecha_nacimiento: originalUsuario.fecha_nacimiento,
          email: originalUsuario.email,
          telefono: originalUsuario.telefono,
        }) || croppedImage !== null
    );
  }, [formData, originalUsuario, croppedImage]);

  const handleSave = async () => {
    if (formData.password && formData.password !== formData.confirmPassword) {
      toast.error("Las contraseñas no coinciden");
      return;
    }

    try {
      let imageUrl = usuario.image_url;

      if (croppedImage) {
        const uploadedImageResponse = await uploadImage(croppedImage);
        imageUrl = uploadedImageResponse.url;
      }

      const payload = {
        ...usuario,
        nombre: formData.nombre,
        telefono: formData.telefono,
        image_url: imageUrl,
      };

      if (formData.password) {
        payload.contrasena = formData.password;
      }

      const response = await updateUsuario(payload);

      const { user, token } = response;
      localStorage.setItem("token", token);
      const decoded = jwtDecode(token);
      updateUser({ ...decoded.data, ...user, image_url: imageUrl });

      setFormData({
        ...formData,
        password: "",
        confirmPassword: "",
      });

      toast.success("Perfil actualizado con éxito");

      setIsChanged(false);
    } catch (error) {
      console.error("Error al actualizar el perfil:", error);
      toast.error("Error al actualizar el perfil");
    }
  };

  const handleCancel = () => {
    setFormData({
      nombre: originalUsuario.nombre,
      fecha_nacimiento: originalUsuario.fecha_nacimiento,
      email: originalUsuario.email,
      telefono: originalUsuario.telefono,
      password: "",
      confirmPassword: "",
    });
    setCroppedImage(null);
  };

  return (
    <div className="container my-4">
      <ToastContainer />
      <div className="row g-3">
        <div className="col-md-12 col-xl-3">
          <div className="h-100 d-flex flex-column justify-content-center align-items-center user-container text-center bg-white shadow-sm rounded-4 px-3 py-4">
            {loading ? <Skeleton variant="circular" width={150} height={150} /> : <img src={userImage || img} alt={userName} className="rounded-circle user-image-profile mx-auto mb-3" />}
            <div className="text-center">
              <div className="d-flex justify-content-center">
                <div className="text-center profile-user-name-wrapper">{loading ? <Skeleton width={200} height={60} /> : <h3 className="fw-bold text-truncate mb-0">{userName}</h3>}</div>
              </div>
              {loading ? <Skeleton width={50} className="mx-auto" /> : <p className="mb-0">{userType}</p>}
            </div>
          </div>
        </div>

        <div className="col-md-12 col-xl-9">
          <div className="h-100 d-flex flex-column bg-white shadow-sm rounded-4 p-4">
            <h4 className="mb-4 fw-">Editar Información de Contacto</h4>
            <div className="d-flex flex-column flex-grow-1">
              <div className="flex-grow-1">
                <div className="row">
                  <div className="col-12 col-md-8 mb-3">
                    {loading ? <Skeleton variant="text" width="100%" height={40} className="mb-2" /> : <label htmlFor="name">Nombre</label>}
                    {loading ? (
                      <Skeleton variant="rectangular" width="100%" height={50} />
                    ) : (
                      <input type="text" className="form-control rounded-3 py-3 my-2" id="name" maxLength={100} placeholder="Ingresa Nombre" value={formData.nombre} onChange={(e) => setFormData({ ...formData, nombre: e.target.value })} />
                    )}
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    {loading ? <Skeleton variant="text" width="100%" height={40} className="mb-2" /> : <label htmlFor="birthday">Fecha de nacimiento</label>}
                    {loading ? (
                      <Skeleton variant="rectangular" width="100%" height={50} />
                    ) : (
                      <input
                        type="date"
                        className="form-control rounded-3 py-3 my-2"
                        id="birthday"
                        disabled
                        aria-describedby="birthdayHelp"
                        placeholder="Ingresa Fecha de nacimiento"
                        value={formData.fecha_nacimiento}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            fecha_nacimiento: e.target.value,
                          })
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4 mb-3">
                    {loading ? <Skeleton variant="text" width="100%" height={40} className="mb-2" /> : <label htmlFor="email">Email</label>}
                    {loading ? (
                      <Skeleton variant="rectangular" width="100%" height={50} />
                    ) : (
                      <input type="email" className="form-control rounded-3 py-3 my-2" id="email" disabled placeholder="Ingresa Email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                    )}
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    {loading ? <Skeleton variant="text" width="100%" height={40} className="mb-2" /> : <label htmlFor="phoneNumber">Número de teléfono</label>}
                    {loading ? (
                      <Skeleton variant="rectangular" width="100%" height={50} />
                    ) : (
                      <input
                        type="tel"
                        minLength={8}
                        maxLength={9}
                        className="form-control rounded-3 py-3 my-2"
                        id="phoneNumber"
                        placeholder="Ingresa Número de teléfono"
                        value={formData.telefono}
                        onChange={(e) => setFormData({ ...formData, telefono: e.target.value })}
                      />
                    )}
                  </div>
                </div>

                <div className="form-group d-flex flex-column mt-2 mb-5">
                  {loading ? <Skeleton variant="text" width="50%" height={40} className="mb-2" /> : <label htmlFor="image">Adjunta tu foto de perfil</label>}
                  {loading ? <Skeleton variant="rectangular" width="60%" height={80} /> : <PictureUpload onImageCropped={setCroppedImage} />}
                </div>

                {showPasswordFields && (
                  <div className="row">
                    <h5>Cambiar contraseña</h5>
                    <div className="col-12 col-md-6 mb-3">
                      <label htmlFor="password">Nueva Contraseña</label>
                      <div className="input-group my-2">
                        <input
                          type={showNewPassword ? "text" : "password"}
                          className="form-control rounded-start py-3"
                          placeholder="Nueva contraseña"
                          value={formData.password}
                          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                        />
                        <span className="input-group-text rounded-end cursor-pointer" onClick={() => setShowNewPassword(!showNewPassword)} style={{ backgroundColor: "white", border: "1px solid #ced4da", padding: "0.5rem" }}>
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label htmlFor="confirmPassword">Confirmar Contraseña</label>
                      <div className="input-group my-2">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          className="form-control rounded-start py-3"
                          placeholder="Confirmar contraseña"
                          value={formData.confirmPassword}
                          onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                        />
                        <span className="input-group-text rounded-end cursor-pointer" onClick={() => setShowConfirmPassword(!showConfirmPassword)} style={{ backgroundColor: "white", border: "1px solid #ced4da", padding: "0.5rem" }}>
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="container px-0">
                <div className="row grid-container-buttons d-flex justify-content-between align-items-stretch">
                  {loading ? (
                    <>
                      <div className="col-12 col-md-6 col-lg-3 mb-3">
                        <Skeleton variant="rectangular" className="rounded-5" width="100%" height={40} />
                      </div>
                      <div className="col-12 col-md-6 col-lg-3 mb-3">
                        <Skeleton variant="rectangular" className="rounded-5" width="100%" height={40} />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12 col-md-6 col-lg-3 mb-3 d-flex align-items-stretch">
                        <button type="button" className="btn-cancelar rounded-5 w-100 py-2" onClick={handleCancel} hidden disabled={!isChanged}>
                          Cancelar
                        </button>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3 mb-3 d-flex align-items-stretch">
                        <button type="button" className="btn-reservar rounded-5 w-100 py-2" onClick={handleSave} disabled={!isChanged}>
                          Guardar
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
