import axios from "axios";
import { jwtDecode } from "jwt-decode";

const BASE_API_URL = process.env.REACT_APP_BASE_URL;

export const loginUserWithGoogle = async (token) => {
  try {
    const decoded = jwtDecode(token);
    const subId = decoded.sub;

    const verifyResponse = await verifySubId(subId);

    if (verifyResponse.exists && verifyResponse.jwtToken) {
      return {
        exists: verifyResponse.exists,
        jwtToken: verifyResponse.jwtToken,
        user: verifyResponse.user,
      };
    }

    return verifyResponse;
  } catch (error) {
    console.error("Error logging in with Google:", error);
    return null;
  }
};

export const verifySubId = async (subId) => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=usuario/verify_sub_id`, {
      params: { sub_id: subId },
    });
    return response.data;
  } catch (error) {
    console.error("Error verifying sub_id:", error);
    return null;
  }
};
