import React, { useEffect, useRef } from "react";
import { useMap } from "@vis.gl/react-google-maps";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { useMapStore } from "./store";

const PoiMarkers = () => {
  const map = useMap();
  const markersRef = useRef({});
  const infoWindowRef = useRef(null);
  const clustererRef = useRef(null);

  const { lugares, selectedLugar, setSelectedLugar } = useMapStore();

  useEffect(() => {
    if (!map || clustererRef.current) {
      return;
    }

    clustererRef.current = new MarkerClusterer({
      map,
      onClusterClick: (event, cluster) => {
        if (!selectedLugar) {
          map.fitBounds(cluster.bounds);
        }
      },
    });
  }, [map, selectedLugar]);

  useEffect(() => {
    if (!map || !clustererRef.current) {
      return;
    }

    clustererRef.current.clearMarkers();

    const markers = lugares.map((lugar) => {
      const marker = new window.google.maps.Marker({
        position: lugar.location,
        map,
      });

      marker.addListener("click", () => handleMarkerClick(lugar, marker));
      markersRef.current[lugar.key] = marker;
      return marker;
    });

    clustererRef.current.addMarkers(markers);

    return () => markers.forEach((marker) => marker.setMap(null));
  }, [lugares, map]);

  const showInfoWindow = (lugar, marker) => {
    console.log("Datos del lugar:", lugar);

    if (infoWindowRef.current) {
      infoWindowRef.current.close();
    }

    const linkUrl = lugar?.key ? `#/court?id=${lugar.key}` : "#";

    const newInfoWindow = new window.google.maps.InfoWindow({
      content: `
        <div>
          <h5 class="mb-3">${lugar.nombre || "Nombre no disponible"}</h5>
          <p><strong>Comuna:</strong> ${lugar.comuna || "Comuna no disponible"}</p>
          <p><strong>Latitud:</strong> ${lugar.location?.lat || "Latitud no disponible"}</p>
          <p><strong>Longitud:</strong> ${lugar.location?.lng || "Longitud no disponible"}</p>
          <a href="${linkUrl}" class="btn btn-primary w-100">Ir</a>
        </div>
      `,
    });

    newInfoWindow.open({ anchor: marker, map, shouldFocus: false });
    infoWindowRef.current = newInfoWindow;

    map.panTo(lugar.location);
    map.setZoom(Math.max(map.getZoom(), 15));
  };

  useEffect(() => {
    if (!selectedLugar || !map) return;

    const marker = markersRef.current[selectedLugar.key];
    if (marker) {
      showInfoWindow(selectedLugar, marker);
    }
  }, [selectedLugar, map]);

  const handleMarkerClick = (lugar, marker) => {
    setSelectedLugar(lugar);
    showInfoWindow(lugar, marker);
  };

  return null;
};

export default PoiMarkers;
