import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./picture-upload.css";
import "./profile.css";
import Ex from "assets/icons/example.png";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const PictureUpload = ({ onImageCropped }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedSize, setCroppedSize] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const cropperRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        resetCropper();
        setFile(selectedFile);
        setError("");
        const reader = new FileReader();
        reader.onload = () => {
          setImagePreview(reader.result);
          setOpen(true);
        };
        reader.readAsDataURL(selectedFile);
      } else {
        setFile(null);
        setError("Solo se permiten archivos JPG y PNG.");
      }
    }
  };

  const resetCropper = () => {
    setImagePreview(null);
    setCroppedImage(null);
    setCroppedSize(null);
    if (cropperRef.current && cropperRef.current.cropper) {
      cropperRef.current.cropper.clear();
      cropperRef.current.cropper.destroy();
    }
  };

  const handleCrop = () => {
    setLoading(true);
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      let quality = 1;
      if (file.size > 3 * 1024 * 1024) {
        quality = 0.9; // if file is larger than 3MB
      }
      if (file.size > 5 * 1024 * 1024) {
        quality = 0.8; // if file is larger than 5MB
      }
      cropper.getCroppedCanvas().toBlob(
        (blob) => {
          if (blob) {
            const fileName = file.name.split(".")[0] + ".webp";
            const croppedFile = new File([blob], fileName, { type: blob.type });
            setCroppedImage(URL.createObjectURL(blob)); // show image preview
            setCroppedSize(blob.size); // get cropped image size
            onImageCropped(croppedFile); // send cropped image to parent component
            setOpen(false);
            setLoading(false);
          }
        },
        "image/webp",
        quality
      );
    } else {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    resetCropper();
  };

  return (
    <div className="row g-2 align-items-center file-upload-container mt-2">
      <div className="col-12 col-md-4">
        <input type="file" id="file" className="inputfile" onChange={handleFileChange} accept="image/jpeg,image/png" />
        <label className="d-flex align-items-center rounded-3 p-3 text-start" htmlFor="file">
          <img src={Ex} alt="" className="placeholder-file-upload rounded" />
          <div className="d-flex flex-column ms-3">
            <span className="fw-bold fs-6">Subir archivos</span>
            <span className="file-type">JPG, PNG</span>
          </div>
        </label>
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle className="px-4">Recortar Imagen</DialogTitle>
        <DialogContent className="px-4">{imagePreview && <Cropper src={imagePreview} style={{ height: 300, width: "100%" }} 
        // aspectRatio={1}
        viewMode={1} 
        guides={false} ref={cropperRef} zoomable={false} />}</DialogContent>
        <DialogActions className="d-flex px-4 pb-4 justify-content-between gap-3">
          <button type="button" className="btn-cancelar rounded-5 p-2" onClick={handleClose}>
            Cancelar
          </button>
          <button type="button" className="btn-reservar rounded-5 p-2" onClick={handleCrop} disabled={loading}>
            {loading ? "Cargando..." : "Guardar"}
          </button>
        </DialogActions>
      </Dialog>

      {/* {imagePreview && !croppedImage && (
        <div className="col-12">
          <Cropper src={imagePreview} style={{ height: 300, width: "100%" }} aspectRatio={1} guides={false} ref={cropperRef} />
          <button className="btn btn-primary mt-3" onClick={handleCrop}>
            Recortar Imagen
          </button>
        </div>
      )} */}
      {croppedImage && (
        <div className="col-12 col-md-4">
          <div className="file-info d-flex align-items-center border rounded-3 p-3">
            <img src={croppedImage} alt="Cropped" className="placeholder-file-upload rounded me-3" />
            <div className="d-flex flex-column">
              <span className="fw-bold fs-6">{file.name.split(".")[0]}</span>
              <span className="file-size text-success">{(croppedSize / 1024).toFixed(2)}KB</span>
            </div>
          </div>
        </div>
      )}
      {error && (
        <div className="col-12 col-md-4">
          <div className="error-message text-danger">{error}</div>
        </div>
      )}
    </div>
  );
};

export default PictureUpload;
