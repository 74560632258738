import axios from "axios";
import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useUserStore } from "store";

const AuthContext = createContext();
const BASE_URL = process.env.REACT_APP_BASE_URL;

const AuthProvider = ({ children }) => {
  const [token] = useState(localStorage.getItem("token") || "");
  const setUser = useUserStore((state) => state.setUser);
  const logout = useUserStore((state) => state.logout);
  const navigate = useNavigate();

  // Normal login function
  const loginAction = async (credential) => {
    const {
      credentials: { email, contrasena },
    } = credential;
    const data = new URLSearchParams();
    data.append("email", email);
    data.append("contrasena", contrasena);

    try {
      //TODO pass this to a service
      const response = await axios.post(`${BASE_URL}?f=usuario/login`, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const { message, token } = response.data;

      localStorage.setItem("token", token);
      const decoded = jwtDecode(token);
      console.log("decode", decoded);

      setUser(decoded);
      navigate("/home");
      return { message, token, payload: decoded };
    } catch (error) {
      console.error("Error logging in user:", error);
      throw error;
    }
  };

  // Google login function
  const googleLoginValidator = async (token) => {
    try {
      const decoded = jwtDecode(token);

      localStorage.setItem("token", token);
      setUser(decoded);

      navigate("/home");
      return { message: "Inicio de sesión exitoso", token: token, payload: decoded };
    } catch (error) {
      console.error("Error logging in with Google:", error);
      throw error;
    }
  };

  const logOut = () => {
    logout();
    localStorage.removeItem("token");
    navigate("/");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUser(decoded);
      } catch (error) {
        console.error("Error decoding token:", error);
        localStorage.removeItem("token");
      }
    } else {
      navigate("/");
    }
  }, [setUser]);

  return <AuthContext.Provider value={{ token, loginAction, googleLoginValidator, logOut }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
