import axios from "axios";

const BASE_API_URL = process.env.REACT_APP_BASE_URL;

/**
 *
 * @param {Number} id recinto_id
 * @returns {Promise<Object>} Recinto
 */
export const fetchInstalacionesByRecintoId = async (id) => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=instalacion/get`, {
      params: { recinto_id: id },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching instalaciones:", error);
    return [];
  }
};

/**
 *
 * @param {Number} id id de instalacion
 * @returns {Promise<Object>} Recinto
 */
export const fetchInstalacionById = async (id) => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=instalacion/get`, {
      params: { id },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching instalaciones:", error);
    return [];
  }
};

/**
 *
 * @param {*} id id de la instalacion
 * @returns
 */

export const fetchRecintoNombreByInstalacionId = async (id) => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=instalacion/getRecintoNombre`, {
      params: { id },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching recinto nombre:", error);
    return { error: "Error fetching recinto nombre" };
  }
};

export const fetchInstalacionCount = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=instalacion/countInstalacion`);
    return response.data.total;
  } catch (error) {
    console.error("Error fetching instalacion count:", error);
    return 0;
  }
};
