import axios from "axios";

const BASE_API_URL = process.env.REACT_APP_BASE_URL;

export const fetchTiposInstalacion = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=tipo_instalacion/get`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tipos de instalación:", error);
    return [];
  }
};
