import React, { useState } from "react";
import { ReactComponent as Star } from "./star.svg";
import "./star.css";

const StarRating = ({ rating = 0, onRatingChange = null, mode = "full" }) => {
  const [hoverRating, setHoverRating] = useState(0);
  const displayRating = hoverRating || rating;
  const fullStars = Math.floor(displayRating);
  const halfStar = displayRating % 1 >= 0.5 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStar;
  const interactive = Boolean(onRatingChange);

  if (mode === "single") {
    return (
      <div className="star-container d-flex align-items-center">
        <span className="me-2 fw-medium ">{rating}</span>
        <Star key="single" className="star" fill="#FFD700" />
      </div>
    );
  }

  if (mode === "view") {
    return (
      <div className="star-container d-flex align-items-center">
        {Array.from({ length: fullStars }, (_, i) => (
          <Star key={`full-${i}`} className="star" fill="#FFD700" />
        ))}
        {halfStar === 1 && (
          <Star key="half" className="star" fill="#FFD700" style={{ opacity: 0.5 }} />
        )}
        {Array.from({ length: emptyStars }, (_, i) => (
          <Star key={`empty-${i}`} className="star star-empty" fill="#ccc" />
        ))}
      </div>
    );
  }

  return (
    <div className="star-container d-flex align-items-center">
      {Array.from({ length: fullStars }, (_, i) => (
        <Star
          key={`full-${i}`}
          className={`star ${interactive ? "interactive" : ""}`}
          onMouseEnter={() => interactive && setHoverRating(i + 1)}
          onMouseLeave={() => interactive && setHoverRating(0)}
          onClick={() => interactive && onRatingChange(i + 1)}
          fill="#FFD700"
        />
      ))}
      {halfStar === 1 && (
        <Star
          key="half"
          className={`star ${interactive ? "interactive" : ""}`}
          onMouseEnter={() => interactive && setHoverRating(fullStars + 1)}
          onMouseLeave={() => interactive && setHoverRating(0)}
          onClick={() => interactive && onRatingChange(fullStars + 1)}
          fill="#FFD700"
          style={{ opacity: 0.5 }}
        />
      )}
      {Array.from({ length: emptyStars }, (_, i) => (
        <Star
          key={`empty-${i}`}
          className={`star star-empty ${interactive ? "interactive" : ""}`}
          onMouseEnter={() =>
            interactive && setHoverRating(fullStars + halfStar + i + 1)
          }
          onMouseLeave={() => interactive && setHoverRating(0)}
          onClick={() =>
            interactive && onRatingChange(fullStars + halfStar + i + 1)
          }
          fill="#ccc"
        />  
      ))}
    </div>
  );
};

export default StarRating;
