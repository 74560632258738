import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './modalsReserva.css';
import StarRating from '../court/star';
import { createValoracion } from 'services/valoracionApi';
import { toast } from 'react-toastify';

const ModalReserva = ({ isOpen, onClose, recintoId, reservaId, usuarioId, onValoracionSubmit }) => {
  const [userRating, setUserRating] = useState(5);
  const [reviewText, setReviewText] = useState('');
  const [isLoading, setIsLoading] = useState(false); 

  if (!isOpen) {
    return null;
  }

  const handleSubmit = async (event) => {
    event.preventDefault(); 

    const trimmedReviewText = reviewText.trim();

    if (!trimmedReviewText) {
      toast.warning('Por favor, escribe una reseña antes de enviar');
      return;
    }

    setIsLoading(true); 

    const valoracionData = {
      reserva_id: reservaId,
      instalacion_id: recintoId,
      valoracion: userRating,
      resena: trimmedReviewText,
      resena_resumen: trimmedReviewText.substring(0, 100), //texto resumido
      usuario_id: usuarioId
    };

    try {
      await createValoracion(valoracionData);
      toast.success('Valoracion recibida con éxito');
      onValoracionSubmit(reservaId);
      onClose();
    } catch (error) {
      console.error("Error al enviar la valoración:", error);
      toast.error(`Error al enviar la valoración: ${error.response.data.message|| 'Algo salió mal'}`);
    } finally {
      setIsLoading(false); 
    }
  };

  return ReactDOM.createPortal(
    <>
      <div className="modal-backdrop show"></div>
      <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1">
        <div className="modal-dialog modal-lg " role="document">
          <div className="modal-content">
            <div className="modal-header sin-border">
              <h3 className="modal-title fw-bold">Valorizar</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <StarRating rating={userRating} onRatingChange={setUserRating} />
                <p className='my-4 fw-bold'>Escribe tu reseña aquí</p>
                <textarea
                  className="form-control text-area-custom rounded-4"
                  placeholder="Escribe aquí..."
                  value={reviewText}
                  onChange={(e) => setReviewText(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="modal-footer d-flex justify-content-between sin-border">
                <button type="button" className="btn-cancelar rounded-5 px-5 py-2" onClick={onClose} disabled={isLoading}>Cancelar</button>
                <button 
                  type="submit" 
                  className="btn-cargar rounded-5 px-5 py-2 text-light"
                  disabled={isLoading} 
                >
                  {isLoading ? (
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="16" 
                      height="16" 
                      fill="currentColor" 
                      className="bi bi-arrow-repeat" 
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.534 7h1.932A6.5 6.5 0 0 0 2.5 8.75.5.5 0 0 0 3.5 9h1.932a5 5 0 1 1 .9-1.972L8.5 9h-1A.5.5 0 0 0 7 9.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-1.362l1.234-1.968A4.5 4.5 0 1 0 11.534 7z"/>
                    </svg>
                  ) : (
                    "Cargar"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>,
    document.getElementById('modal-root')
  );
};

export default ModalReserva;
