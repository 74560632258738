import DataTable, { createTheme } from "react-data-table-component";
import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchMiniReservaById, fetchReservaDetalles } from "services/reservaApi";
import "./resumen-reservas.css";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import { useUserStore } from "store";
import { formatCurrencyCLP } from "utils/format";

function ResumenReservas() {
  const location = useLocation();
  const navigate = useNavigate();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const [filterText, setFilterText] = useState("");
  const [reservaData, setReservaData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get("page") || "1"));
  const [pageSize, setPageSize] = useState(parseInt(searchParams.get("pageSize") || "5"));
  const [totalRecords, setTotalRecords] = useState(0);
  const reservanteId = useUserStore((state) => state.user?.data?.id);

  useEffect(() => {
    const fetchData = async () => {
      if (!reservanteId) return;
      setLoading(true);
      const response = await fetchMiniReservaById(reservanteId, currentPage, pageSize);
      console.log(response)
      setTimeout(() => {
        if (response) {
          setReservaData(response.data);
          setTotalRecords(response.totalRecords);
        } else {
          setReservaData([]);
          setTotalRecords(0);
        }
        setLoading(false);
      }, 800); // Retraso para mostrar loader 800ms esta bien pero podria ser mas 1s o 1.2s
    };

    fetchData();
  }, [currentPage, pageSize, reservanteId]);

  const handleFetchDetails = async (reservaId) => {
    const detailsArray = await fetchReservaDetalles(reservanteId, reservaId);
    if (detailsArray && detailsArray.length > 0) {
      const details = detailsArray[0];
      setReservaData((currentData) => currentData.map((item) => (item.reserva_id === reservaId ? { ...item, ...details, detailsLoaded: true } : item)));
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(location.search);
    params.set("page", page);
    navigate({ search: params.toString() }, { replace: true });
  };

  const handlePageSizeChange = (newPageSize, page) => {
    setPageSize(newPageSize);
    setCurrentPage(page);
    const params = new URLSearchParams(location.search);
    params.set("pageSize", newPageSize);
    params.set("page", page);
    navigate({ search: params.toString() }, { replace: true });
  };

  const paginationOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
      },
    },
    cells: {
      style: {
        paddingTop: "10px",
        paddingBottom: "10px",
      },
    },
  };

  createTheme(
    "tabla-reserva",
    {
      text: {
        primary: "black",
        secondary: "black",
      },
      background: {
        default: "unset",
      },
      context: {
        text: "#FFFFFF",
      },
      divider: {
        default: "#e0dcdc",
      },
      button: {
        default: "green",
        hover: "#ebebeb",
        focus: "rgba(255,255,255,.12)",
        disabled: "rgba(255, 255, 255, .34)",
      },
      sortFocus: {
        default: "#2aa198",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Nombre del recinto",
      selector: (row) => row.recinto_nombre,
      wrap: true,
    },
    {
      name: "Comuna",
      selector: (row) => row.comuna,
      wrap: true,
    },
    {
      name: "Dirección",
      selector: (row) => row.direccion,
      wrap: true,
    },
    {
      name: "Fecha Inicio",
      selector: (row) => formatearFecha(row.fecha_hora_inicio),
      wrap: true,
    },
    {
      name: "Valor por hora (CLP)",
      selector: (row) => formatCurrencyCLP(row.valor_total),
      wrap: true,
    },
    {
      name: "Tipo Instalación",
      selector: (row) => row.tipo_instalacion_nombre,
      wrap: true,
    },
    {
      name: "Estado",
      selector: (row) => (row.estado_reserva_nombre === "Disponible" ? "Caducado" : row.estado_reserva_nombre),
      wrap: true,
    },
    {
      name: "Acciones",
      cell: (row) => (
        <Link to={`/detail-booking?id=${row.reserva_id}`} className="text-success">
          <Visibility />
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  function formatearFecha(fechaString) {
    const fecha = new Date(fechaString);
    const opcionesFecha = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const opcionesHora = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    const parteFecha = fecha.toLocaleDateString("es", opcionesFecha).split(" ");
    const parteHora = fecha.toLocaleTimeString("es", opcionesHora);
    const fechaFormateada = `${parteFecha[0]} de ${parteFecha[2]}, ${parteFecha[4]} ${parteHora}`;
    return fechaFormateada;
  }

  const filteredItems = reservaData.filter((item) => {
    const removeDiacritics = (text) => (text ? text.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "");

    const searchTextLower = removeDiacritics(filterText.toLowerCase());

    const createdDateFormatted = item.fecha_hora_inicio ? formatearFecha(item.fecha_hora_inicio) : "";
    // const endDateFormatted = item.fecha_termino ? formatearFecha(item.fecha_termino) : "";

    const mainFilter =
      removeDiacritics(item.recinto_nombre?.toLowerCase()).includes(searchTextLower) ||
      removeDiacritics(item.comuna?.toLowerCase()).includes(searchTextLower) ||
      removeDiacritics(createdDateFormatted.toLowerCase()).includes(searchTextLower) ||
      removeDiacritics(item.direccion?.toLowerCase()).includes(searchTextLower) ||
      removeDiacritics(item.valor_total?.toLowerCase()).includes(searchTextLower) ||
      removeDiacritics(item.tipo_instalacion_nombre?.toLowerCase()).includes(searchTextLower) ||
      removeDiacritics(item.estado_reserva_nombre?.toLowerCase()).includes(searchTextLower);

    return mainFilter;
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);

  const ExpandableComponent = ({ data }) => {
    if (!data.detailsLoaded) {
      handleFetchDetails(data.reserva_id);
      return (
        <div className="container d-flex justify-content-center align-items-center p-5">
          <CircularProgress color="success" />
        </div>
      );
    }

    const dataSeleccionada = data;
    return (
      <div className="expandable-content p-3">
        <div className="row">
          <div className="col">
            <ul className="list-group rounded-0">
              <li className="list-group-item color-li">
                <strong>Nombre de Instalación:</strong> {dataSeleccionada.instalacion_nombre}
              </li>
              <li className="list-group-item color-li">
                <strong>Fecha Término:</strong> {formatearFecha(dataSeleccionada.fecha_termino)}
              </li>
              <li className="list-group-item color-li">
                <strong>URL:</strong>{" "}
                <a href={dataSeleccionada.url} target="_blank" rel="noopener noreferrer">
                  {dataSeleccionada.url}
                </a>
              </li>
              <li className="list-group-item color-li">
                <strong>Teléfono:</strong> {dataSeleccionada.telefono}
              </li>
              <li className="list-group-item color-li">
                <strong>Email:</strong> {dataSeleccionada.email}
              </li>
            </ul>
          </div>
          <div className="col">
            <ul className="list-group rounded-0">
              <li className="list-group-item color-li">
                <strong>Casilleros:</strong> {dataSeleccionada.casilleros ? "Sí" : "No"}
              </li>
              <li className="list-group-item color-li">
                <strong>Estacionamiento:</strong> {dataSeleccionada.estacionamiento ? "Sí" : "No"}
              </li>
              <li className="list-group-item color-li">
                <strong>Público:</strong> {dataSeleccionada.publico ? "Sí" : "No"}
              </li>
              <li className="list-group-item color-li">
                <strong>Camarines:</strong> {dataSeleccionada.camarines ? "Sí" : "No"}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid tabla-wrapper rounded">
      <DataTable
        title={<DataTableTitle />}
        columns={columns}
        data={filteredItems}
        className="tabla-wrapper"
        customStyles={customStyles}
        theme="tabla-reserva"
        pagination
        paginationServer
        paginationDefaultPage={currentPage}
        paginationTotalRows={totalRecords}
        paginationComponentOptions={paginationOptions}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePageSizeChange}
        paginationPerPage={pageSize}
        paginationRowsPerPageOptions={[5, 10, 15, 30]}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        responsive
        defaultSortFieldId={2024}
        defaultSortAsc={true}
        expandableRows
        expandableRowsComponent={ExpandableComponent}
        noDataComponent={<div style={{ margin: "20px" }}>No hay registros para mostrar</div>}
        progressPending={loading}
        progressComponent={
          <div className="container d-flex justify-content-center align-items-center p-5">
            <CircularProgress color="success" />
          </div>
        }
      />
    </div>
  );
}

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <TextField
    id="search"
    type="text"
    variant="outlined"
    size="small"
    placeholder="Buscar..."
    value={filterText}
    onChange={onFilter}
    InputProps={{
      endAdornment: filterText && (
        <InputAdornment position="end">
          <IconButton onClick={onClear}>
            <CloseIcon className="close-icon-green" />
          </IconButton>
        </InputAdornment>
      ),
    }}
    className="filter-component"
  />
);

const DataTableTitle = () => (
  <div className="data-table-title">
    <h2 className="fw-bold">Mis reservas</h2>
  </div>
);

export default ResumenReservas;
