import axios from "axios";

const BASE_PAYMENT_URL = process.env.REACT_APP_BASE_PAYMENT_URL;

export const createPreference = async (preferenceData) => {
  try {
    const response = await axios.post(`${BASE_PAYMENT_URL}/preference`, preferenceData);
    return response.data;
  } catch (error) {
    console.error("Error al crear la preferencia de pago:", error.response?.data || error.message);
    throw error;
  }
};
