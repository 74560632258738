import React, { useState, useEffect } from "react";
import { getFavoritoData, removeFavorito } from "services/favoritoApi";
import { useUserStore } from "store";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import StarRating from "components/court/star";
import "./favorite.css";
import placeholderImage from 'assets/images/placeholder.webp'; 

const Favorite = () => {
  const userId = useUserStore((state) => state.user?.data?.id);
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFavorites = async () => {
    setLoading(true);
    try {
      const data = await getFavoritoData(userId);
      console.log('data:',data)
      setFavorites(data);
    } catch (error) {
      console.error("Error al obtener los favoritos:", error);
      setFavorites([]);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveFavorite = async (recintoId) => {
    try {
      await removeFavorito(userId, recintoId);
      setFavorites((prevFavorites) => prevFavorites.filter((fav) => fav.id !== recintoId));
    } catch (error) {
      console.error("Error al eliminar el favorito:", error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchFavorites();
    }
  }, [userId]);


  return (
    <div className="container my-4">
      <div className="h-100 d-flex flex-column bg-white shadow-sm rounded-4 p-4">
        <h4 className="mb-4">Mis Favoritos</h4>
        <div className="d-flex flex-column flex-grow-1">
          {loading ? (
            Array.from(new Array(3)).map((_, index) => (
              <Skeleton key={index} variant="rectangular" width="100%" height={150} className="mb-3" />
            ))
          ) : favorites.length > 0 ? (
            favorites.map((recinto) => (
              <div key={recinto.id} className="card mb-3 favorite-card">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img src={recinto.image_url || placeholderImage} className="img-fluid rounded-start favorite-img w-100 object-cover object-center" alt={recinto.nombre} />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">{recinto.nombre}</h5>
                      <p className="card-text">{recinto.descripcion}</p>
                      <p className="card-text">
                        <small className="text-muted">
                          {recinto.direccion}, {recinto.comuna}
                        </small>
                      </p>
                      <div className="d-flex align-items-center">
                        <StarRating rating={parseFloat(recinto.valoracion_promedio)} />
                        <span className="ms-2">({recinto.valoracion_promedio})</span>
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <button
                          className="btn-heart border-0 p-2"
                          onClick={() => handleRemoveFavorite(recinto.id)}
                        >
                        Eliminar de favoritos
                        </button>
                        <Link to={`/court/?id=${recinto.id}`} className="btn-reservar rounded-5 p-2 ">
                          Ver Recinto
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No tienes recintos favoritos aún.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Favorite;
