import React, { useState } from "react";
import "./register.css";
import loginLogo from "assets/images/courapp-login.png";
import wsp from "assets/icons/rrss/Whatsapp.svg";
import ig from "assets/icons/rrss/Instagram.svg";
import fb from "assets/icons/rrss/Facebook.svg";
import li from "assets/icons/rrss/Linkedin.svg";
import yt from "assets/icons/rrss/Youtube.svg";
import mg from "assets/icons/rrss/Messenger.svg";
import "@fontsource/poppins";
import { registerUser } from "services/authApi";
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox, FormControl, FormControlLabel, TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/es";

const Register = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [pre_phone, setPrePhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [birthday, setBirthday] = useState(null);
  const [birthdayError, setBirthdayError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const location = useLocation();
  const googleUserData = location.state?.googleUserData || {};
  const [name, setName] = useState(googleUserData.name || "");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState(googleUserData.email || "");
  const [sub_id, setSubId] = useState(googleUserData.sub || null);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!/\S+@\S+\.\S+/.test(e.target.value)) {
      setEmailError("Correo electrónico no válido");
    } else if (e.target.value.length > 100) {
      setEmailError("El correo no puede exceder los 100 caracteres");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (e.target.value.length < 6) {
      setPasswordError("La contraseña debe tener al menos 6 caracteres");
    } else if (e.target.value.length > 50) {
      setPasswordError("La contraseña no puede exceder los 50 caracteres");
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value !== password) {
      setConfirmPasswordError("Las contraseñas no coinciden");
    } else if (e.target.value.length < 6) {
      setConfirmPasswordError("La contraseña debe tener al menos 6 caracteres");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    if (value.length < 3) {
      setNameError("El nombre debe tener al menos 3 caracteres");
    } else if (value.length > 100) {
      setNameError("El nombre no puede exceder los 100 caracteres");
    } else {
      setNameError("");
    }
  };

  const handlePrePhoneChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setPrePhone(value);
    if (value.length < 8 || value.length > 9) {
      setPhoneError("El teléfono debe ser valido");
    } else {
      setPhoneError("");
    }
  };

  const handleBirthdayChange = (newValue) => {
    setBirthday(newValue);
    if (newValue && dayjs(newValue).isAfter(dayjs(), "day")) {
      setBirthdayError("La fecha de nacimiento no puede ser futura");
    } else {
      setBirthdayError("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!emailError && !passwordError && !confirmPasswordError && !birthdayError && !nameError && !phoneError) {
      setIsLoading(true);
      const formattedBirthday = birthday ? dayjs(birthday).format("YYYY-MM-DD") : null;

      const userData = {
        email: email,
        contrasena: password || null,
        tipo: "Usuario",
        nombre: name,
        telefono: pre_phone,
        fecha_nacimiento: formattedBirthday,
        sub_id: sub_id,
      };

      try {
        await registerUser(userData);
        toast.success("Usuario registrado correctamente");
        navigate("/");
      } catch (error) {
        const errorMessage = error.response?.data?.message || "Error al registrar el usuario";
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error("Por favor, corrige los errores antes de enviar");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <div className="login-container min-vh-100 d-flex justify-content-center align-items-center">
        <div className="container container-register">
          <div className="row">
            <div className="col-lg-12 text-center">
              <img src={loginLogo} alt="Logo" className="login-logo mb-3" />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 text-center">{/* Espacio reservado */}</div>
            <div className="col-2">{/* Espacio reservado */}</div>
            <div className="col-sm-8 ">
              <div className="card px-4 py-2 mb-2">
                <div className="title my-2">
                  Crea tu cuenta en <dest className="dest">CourApp</dest>
                </div>

                <div className="text-start my-2">
                  <button className="btn text-decoration-underline text-muted  mb-2" onClick={() => navigate("/")}>
                    Volver
                  </button>
                </div>
                <FormControl>
                  <form onSubmit={handleSubmit} className="form m-2">
                    <TextField
                      className="form-input w-100 mt-1 mb-2"
                      value={name}
                      onChange={handleNameChange}
                      label="Nombre"
                      variant="outlined"
                      required
                      inputProps={{ maxLength: 100, minLength: 3 }}
                      error={!!nameError}
                      helperText={nameError}
                    />
                    <TextField
                      className="form-input w-100 mt-1 mb-2"
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      label="Correo Electrónico"
                      variant="outlined"
                      required
                      error={!!emailError}
                      helperText={emailError}
                      disabled={!!googleUserData.email}
                      inputProps={{ maxLength: 100 }}
                    />
                    <TextField
                      className="form-input w-100 mt-1 mb-2"
                      value={pre_phone}
                      onChange={handlePrePhoneChange}
                      label="Teléfono Celular (+56)"
                      variant="outlined"
                      required
                      inputProps={{ maxLength: 9, minLength: 8 }}
                      error={!!phoneError}
                      helperText={phoneError}
                    />
                    {!googleUserData.sub && (
                      <>
                        <TextField
                          className="form-input w-100 mt-1 mb-2"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={handlePasswordChange}
                          label="Contraseña"
                          variant="outlined"
                          required
                          error={!!passwordError}
                          helperText={passwordError}
                          InputProps={{
                            minLength: 6,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          className="form-input w-100 mt-1 mb-2"
                          type={showConfirmPassword ? "text" : "password"}
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                          label="Confirme contraseña"
                          variant="outlined"
                          required
                          error={!!confirmPasswordError}
                          helperText={confirmPasswordError}
                          InputProps={{
                            minLength: 6,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" onClick={handleClickShowConfirmPassword} edge="end">
                                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </>
                    )}
                    <DatePicker
                      label="Fecha de Nacimiento"
                      className="w-100 mt-1 mb-2"
                      value={birthday}
                      onChange={handleBirthdayChange}
                      openTo="year"
                      format="DD/MM/YYYY"
                      disableFuture
                      renderInput={(params) => <TextField {...params} helperText={birthdayError || "DD/MM/AAAA"} error={!!birthdayError} required />}
                    />
                    <FormControlLabel className="my-2" control={<Checkbox defaultChecked />} label="Acepto los términos y condiciones" required />
                    <div className="container d-flex justify-content-center">
                      <button type="submit" className="register-button w-50 text-white border-0 rounded p-2" disabled={isLoading}>
                        {isLoading ? "Registrando..." : "REGISTRARSE"}
                      </button>
                    </div>
                  </form>
                </FormControl>
                <div className="container py-2 text-center text-muted ">Si tienes problemas registrándote, contáctanos a contacto@courapp.cl</div>
              </div>
            </div>
            <div className="col-sm-2 col-12 py-sm-3">
              <div className="login-links d-flex flex-row flex-sm-column gap-3">
              <a href="https://api.whatsapp.com/send?phone=56966109061" target="_blank" rel="noopener noreferrer">
                <img src={wsp} alt="Whatsapp" className="social-icon" />
              </a>
              <a href="https://www.instagram.com/courapp_chile/profilecard/" target="_blank" rel="noopener noreferrer">
                <img src={ig} alt="Instagram" className="social-icon" />
              </a>
                {/* <img src={fb} alt="Facebook" className="social-icon" />
                <img src={yt} alt="Youtube" className="social-icon" />
                <img src={mg} alt="Messenger" className="social-icon" />
                <img src={li} alt="Linkedin" className="social-icon" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default Register;
