import React, { useState, useEffect } from 'react';
import './places.css';
import '../court/courtCarousel.css'
import StarRating from '../court/star';
import StatePill from '../../basepage/statecourt';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import placeholderImage from 'assets/images/placeholder.webp'; 

const PlaceInfo = ({ id, images, name, location, note, webPage, email, phone, totalInstalaciones}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      setLoading(false);
  }, []);

  const imageUrl = images?.length > 0 ? images : placeholderImage;
  const isAvailable = totalInstalaciones > 0;


  return (
    <div className="card card-custom h-100 rounded-4">
      <div className="card-body d-flex flex-column">
        {loading ? (
          <Skeleton variant="rectangular" height={200} />
        ) : (
          <div className="carousel-item-fixed">
          <img
            className="d-block w-100 rounded-4"
            src={imageUrl}
            alt={`Imagen de ${name}`}
          />
        </div>
        )}
        <div className="position-relative my-2">
          {loading ? (
            <Skeleton variant="rectangular" width="50%" height={30} className="mx-auto" />
          ) : (
            <StatePill customClass="p-2 p-md-3 w-50 mx-auto" circleClass="mx-1" textClass="mx-1" isActive={isAvailable} />
          )}
        </div>
        {loading ? (
          <Skeleton variant="text" width="80%" className="mx-auto" />
        ) : (
          <h3 className="card-title text-center text-truncate fw-bold">{name}</h3>
        )}
        {loading ? (
          <Skeleton variant="text" width="60%" className="mx-auto" />
        ) : (
          <p className="text-center lh-1 mb-0">Ubicación</p>
        )}
        {loading ? (
          <Skeleton variant="text" width="60%" className="mx-auto" />
        ) : (
          <p className="text-center text-truncate fw-bolder">{location}</p>
        )}
        <div className="d-flex justify-content-center align-items-center mb-3">
          {loading ? (
            <>
              <Skeleton variant="rectangular" width="50%" height={40} />
              <Skeleton variant="text" width={30} height={30} className="ms-2 mt-1" />
            </>
          ) : (
            <>
              <StarRating rating={note} />
              <span className="ms-2 mt-1 fw-bold" style={{ fontSize: '20px' }}>{note}</span>
            </>
          )}
        </div>
        <ul className="list-group list-group-flush mb-4">
          <li className="list-group-item list-group-item-places d-flex justify-content-between align-items-center text-truncate">
            {loading ? (
              <Skeleton variant="text" width="60%" />
            ) : (
              <span>Correo Electrónico:</span>
            )}
            {loading ? (
              <Skeleton variant="text" width="50%" />
            ) : (
              <span className='fw-bold mx-2'>{email}</span>
            )}
          </li>
          <li className="list-group-item list-group-item-places d-flex justify-content-between align-items-center text-truncate">
            {loading ? (
              <Skeleton variant="text" width="60%" />
            ) : (
              <span>Página Web:</span>
            )}
            {loading ? (
              <Skeleton variant="text" width="50%" />
            ) : (
              <a className='fw-bold' href={webPage} target="_blank" rel="noopener noreferrer">{webPage}</a>
            )}
          </li>
          <li className="list-group-item list-group-item-places d-flex justify-content-between align-items-center text-truncate">
            {loading ? (
              <Skeleton variant="text" width="60%" />
            ) : (
              <span>Teléfono:</span>
            )}
            {loading ? (
              <Skeleton variant="text" width="50%" />
            ) : (
              <span className='fw-bold'>{phone}</span>
            )}
          </li>
        </ul>
      </div>
      <div className="card-footer card-footer-custom d-flex justify-content-center align-items-center my-2">
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height={40} />
        ) : isAvailable ? (
          <Link
            to={`/court?id=${id}`}
            className="btn-reservar rounded-5 px-3 px-md-5 py-2 w-100 text-center text-decoration-none"
          >
            Reservar/Detalles
          </Link>
        ) : (
          <Link
            to={`/court?id=${id}`}
            className="btn-reservar rounded-5 px-3 px-md-5 py-2 w-100 text-center text-decoration-none"
          >
            Sin instalaciones disponibles
          </Link>
        )}
      </div>
    </div>
  );
};

export default PlaceInfo;
