import React from 'react';
import './footer.css'; // Import the CSS file
import '@fontsource/poppins';
import logo_name from 'assets/images/logo+name.svg';
import wsp from 'assets/icons/rrss/Whatsapp2.svg';
import ig from 'assets/icons/rrss/Instagram2.svg';
import fb from 'assets/icons/rrss/Facebook2.svg';
import yt from 'assets/icons/rrss/Youtube2.svg';
import {ReactComponent as Location} from 'assets/icons/footer-images/locationIcon.svg';
import {ReactComponent as Mail} from 'assets/icons/footer-images/emailIcon.svg';
import {ReactComponent as Phone} from 'assets/icons/footer-images/phoneIcon.svg';

function Footer() {


  return (
    <footer className="footer d-flex flex-wrap justify-content-center align-items-center text-center">
      <div className="container-md py-3">
        <div className="row">
          <div className="col-12 col-lg-4 mb-3">
            <div className="text-start text-center text-lg-start d-flex flex-wrap align-items-center">
              <img src={logo_name} alt="CourApp Logo Name" className="footer-logo-name" />
              <p>Descubre la manera más sencilla de reservar tu espacio deportivo con nuestra plataforma. Encuentra y reserva recintos para tus entrenamientos, partidos y otros eventos deportivos, todo desde Courapp. ¡Optimiza tu tiempo y disfruta del deporte sin complicaciones!</p>
            </div>
          </div>
          <div className="col-12 col-lg-4 mb-3">
            <div className="container d-flex flex-wrap justify-content-center align-items-center">
              <p className="d-block fw-bold text-center text-lg-start">Estamos activos en nuestras Redes Sociales</p>
              <div className="container-fluid d-flex justify-content-center gap-2">
                {/* <img src={yt} alt="Youtube Logo" className="social-media-logo" />
                <img src={fb} alt="Facebook Logo" className="social-media-logo" /> */}
                <a href="https://www.instagram.com/courapp_chile/profilecard/" target="_blank" rel="noopener noreferrer">
                  <img src={ig} alt="Instagram Logo" className="social-media-logo" />
                </a>
                <a href="https://api.whatsapp.com/send?phone=56966109061" target="_blank" rel="noopener noreferrer">
                  <img src={wsp} alt="WhatsApp Logo" className="social-media-logo" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 mb-3">
            <div className="contact-info text-center text-lg-start">
              <p className="fw-bold mb-4">Póngase en contacto con nosotros</p>
              <ul className="list-unstyled d-flex flex-column align-items-center align-items-lg-start">
                <li className="d-flex align-items-center gap-2 mb-4">
                  <span><Location /></span>
                  <span className="text-center text-lg-start">Los Ángeles, Chile.</span>
                </li>
                <li className="d-flex align-items-center gap-2 mb-4">
                  <span><Phone /></span>
                  <span className="text-center text-lg-start">+5 69 6610 9061</span>
                </li>
                <li className="d-flex align-items-center gap-2 mb-4">
                  <span><Mail /></span>
                  <span className="text-center text-lg-start">
                    <a href="mailto:contacto@courapp.cl" className="contact-link text-decoration-none">contacto@courapp.cl</a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 reservados-section py-4">
        Todos los derechos reservados © CourApp {new Date().getFullYear()}
      </div>
    </footer>

  );
}

export default Footer;
