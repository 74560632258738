import React, { useEffect, useState } from "react";
import Fire from "assets/icons/fire.svg";
import Ref from "assets/icons/referee.svg";
import Time from "assets/icons/time.svg";
import { fetchRecintoCount } from "services/recintoApi";
import { fetchInstalacionCount } from "services/instalacionApi";
import { fetchHorasReservadas } from "services/reservaApi";
import { useUserStore } from "store";

const Stats = () => {
  const [stats, setStats] = useState([
    { title: "Recintos", value: '-', ph: Ref, subtitle: "Activos", bgClass: 'bg-clubes' },
    { title: "Instalaciones", value: '-', ph: Fire, subtitle: "Activas", bgClass: 'bg-canchas-activas' },
    { title: "Horas reservadas", value: '-', ph: Time, subtitle: "", bgClass: 'bg-duracion-total' },
  ]);
  const userId = useUserStore((state) => state.user?.data?.id);

  const fetchData = async () => {
    try {
      const recintoCount = await fetchRecintoCount();
      const instalacionCount = await fetchInstalacionCount();
      const horasReservadas = await fetchHorasReservadas(userId); 

      setStats(prevStats => prevStats.map(stat => {
        if (stat.title === "Recintos") return { ...stat, value: recintoCount };
        if (stat.title === "Instalaciones") return { ...stat, value: instalacionCount };
        if (stat.title === "Horas reservadas") return { ...stat, value: horasReservadas || 0 };
        return stat;
      }));
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  return (
    <div className="container">
      <div className="row">
        {stats.map((stat, index) => (
          <div key={index} className="col-12 col-sm-6 col-lg-3 mb-4">
            <div className={`card h-100 d-flex flex-column border-0 ${stat.bgClass}`}>
              <div className="card-body d-flex flex-column position-relative">
                <div className="overlay"></div>
                <h4 className="card-title text-start mb-3 fw-bold text-white">{stat.title}</h4>
                <div className="row mt-auto">
                  <div className="col-7 col-md-12 col-lg-7 mb-3 mb-lg-0">
                    <div className="d-flex flex-column justify-content-center">
                      <p className="mb-0 text-valor text-white fw-bold">{stat.value}</p>
                      <p className="mb-0 text-subtitulo text-white">{stat.subtitle}</p>
                    </div>
                  </div>
                  <div className="col-5 col-md-12 col-lg-5 d-flex justify-content-center align-items-center">
                    <img src={stat.ph} alt={stat.title} className="stat-image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stats;
