import axios from "axios";
import Cookies from "js-cookie";
import * as jose from "jose";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const registerUser = async (userData) => {
  const { usuario, contrasena, tipo, email, nombre, telefono, fecha_nacimiento, sub_id } = userData;

  const data = new URLSearchParams();
  data.append("email", email);
  if (contrasena) data.append("contrasena", contrasena); // Solo incluir contraseña si está presente
  data.append("tipo", tipo);
  data.append("nombre", nombre);
  data.append("telefono", telefono);
  data.append("fecha_nacimiento", fecha_nacimiento);
  if (sub_id) data.append("sub_id", sub_id); // Solo incluir sub_id si está presente

  try {
    const response = await axios.post(`${BASE_URL}?f=usuario/insert`, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error registering user:", error);
    throw error;
  }
};

export const registerUserGoogle = async (userData) => {
  return registerUser(userData);
};

export const loginUser = async (credentials) => {
  const { email, contrasena } = credentials;

  const data = new URLSearchParams();
  data.append("email", email);
  data.append("contrasena", contrasena);

  try {
    const response = await axios.post(`${BASE_URL}?f=usuario/login`, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const { message, token, duration } = response.data;
    const secretKeyUint8Array = new TextEncoder().encode("2c51ba17f99ac061530b021eddb9c6fc407c27be1cfd1e01896f73f251ffcfac");
    const { payload, protectedHeader } = await jose.jwtVerify(token, secretKeyUint8Array);

    localStorage.setItem("token", token);
    return { message, token, duration, payload, protectedHeader };
  } catch (error) {
    console.error("Error logging in user:", error);
    throw error;
  }
};

export const getTokenFromLocalStorage = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("No Token");
    }

    const secretKeyUint8Array = new TextEncoder().encode("2c51ba17f99ac061530b021eddb9c6fc407c27be1cfd1e01896f73f251ffcfac");
    const { payload } = await jose.jwtVerify(token, secretKeyUint8Array);

    const email = payload.data.email;
    const tipo = payload.data.tipo;
    const name = payload.data.nombre;
    const fecha_nacimiento = payload.data.fecha_nacimiento;
    const id = payload.data.id;

    const setSecureCookie = (name, value, expires) => {
      Cookies.set(name, value, {
        expires: expires,
        secure: true,
        sameSite: "Strict",
      });
    };

    setSecureCookie("email", email, 0.25);
    setSecureCookie("tipo", tipo, 0.25);
    setSecureCookie("name", name, 0.25);
    setSecureCookie("fecha_nacimiento", fecha_nacimiento, 0.25);
    setSecureCookie("id", id, 0.25);

    if (!name) {
      throw new Error("Name not found in token payload");
    }
    return name;
  } catch (error) {
    console.error("Error decoding token:", error);
    return "Usuario no iniciado";
  }
};

export const changeProfile = async (newData = {}) => {
  try {
    const id = Cookies.get("id");
    if (!id) {
      throw new Error("ID not found in cookies");
    }
    const response = await axios.get(`${BASE_URL}?f=usuario/get&id=${id}`);
    const currentData = response.data;

    const updatedData = { ...currentData, ...newData };

    const params = new URLSearchParams();
    for (const key in updatedData) {
      params.append(key, updatedData[key]);
    }
    // FALTA SABER EL COMANDO PUT DEL BACKEND
    const updateResponse = await axios.put(`${BASE_URL}?f=usuario/update&id=${id}`, params);
    return updateResponse.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await axios.get(`${BASE_URL}?f=usuario/forgot-password`, {
      params: { email },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.mensaje || "Error al intentar recuperar la contraseña.";
  }
};
