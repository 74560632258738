import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";
import CourtExample from "assets/images/court_example.png";
import "./detailsBooking.css";
import { cancelReserva, fetchReservaDetalles } from "services/reservaApi";
import Cookies from "js-cookie";
import ModalReserva from "components/modals/modalsReserva";
import StarRating from "components/court/star";
import { toast } from "react-toastify";
import ModalConfirmCancel from "./modalConfirmCancel";
import { useUserStore } from "store";
import { formatCurrencyCLP } from "utils/format";

function DetailsBooking() {
  const [reservaDetalles, setReservaDetalles] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingUser, setLoadingUser] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const reservanteId = Cookies.get("id");
  const reservanteId = useUserStore((state) => state.user?.data?.id);

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const reservaId = searchParams.get("id");
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);

  const handleOpenCancelModal = () => setIsCancelModalOpen(true);
  const handleCloseCancelModal = () => setIsCancelModalOpen(false);

  const fetchDetails = async () => {
    try {
      const data = await fetchReservaDetalles(reservanteId, reservaId);
      if (data && data.length > 0) {
        setReservaDetalles(data[0]);
      } else {
        setReservaDetalles(null);
      }
    } catch (error) {
      console.error("Error fetching reserva detalles:", error);
      setReservaDetalles(null);
    } finally {
      setLoading(false);
    }
  };

  const formatEstadoReserva = (estado) => {
    switch (estado) {
      case "0":
        return "Caducado";
      case "1":
        return "Reservado";
      case "2":
        return "Concretado";
      case "3":
        return "Anulado";
      case "4":
        return "Pendiente";
      default:
        return "Desconocido";
    }
  };

  const formatTime = (dateString) => {
    if (!dateString) return "Hora no disponible";
    const date = new Date(dateString);
    if (isNaN(date)) return "Hora inválida";
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "Fecha no disponible";
    const date = new Date(dateString);
    if (isNaN(date)) return "Fecha inválida";
    return date.toLocaleDateString();
  };

  const formatCLP = (value) => {
    if (!value) return "$0/hr";
    return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}/hr`;
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleValoracionSubmit = async () => {
    setIsModalOpen(false);
    setLoading(true);
    await fetchDetails();
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleReserveAgain = () => {
    if (reservaDetalles?.recinto_id && reservaDetalles?.instalacion_id) {
      navigate(`/court?id=${reservaDetalles.recinto_id}&instalacion_id=${reservaDetalles.instalacion_id}`);
    } else {
      console.error("No se encontró el recinto_id o instalacion_id para redirigir.");
      toast.error("Error al reservar nuevamente, intente en otro momento");
    }
  };

  const handleConfirmCancel = async () => {
    setIsLoadingCancel(true);
    try {
      const response = await cancelReserva(reservaId);
      if (response && response.message === "Reserva cancelada exitosamente") {
        toast.success("Reserva cancelada correctamente");
        setLoading(true);
        await fetchDetails();
      } else {
        toast.error(response?.message || "Error al cancelar la reserva. Intente nuevamente.");
      }
    } catch (error) {
      console.error("Error al cancelar la reserva:", error);
      const errorMessage = error.response?.data?.message || "Ocurrió un error al cancelar la reserva.";
      toast.error(errorMessage);
    } finally {
      setIsLoadingCancel(false);
      setIsCancelModalOpen(false);
    }
  };

  const handleCheckout = () => {
    navigate(`/checkout/${reservaId}`);
  };

  useEffect(() => {
    if (reservanteId) {
      setLoadingUser(false);
    }
  }, [reservanteId]);

  useEffect(() => {
    if (!loadingUser && reservanteId && reservaId) {
      fetchDetails();
    }
  }, [loadingUser, reservanteId, reservaId]);

  if (loading) {
    return (
      <div className="court container-md">
        <Skeleton variant="rectangular" width="100%" height={200} />
      </div>
    );
  }

  if (!reservaDetalles) {
    return (
      <div className="court container-md">
      <div className="bg-white rounded-4 p-3 my-4">
        <div className="container-fluid">
          <h3 className="my-3 fw-medium">Detalle de Reserva</h3>
        <h3>No se encontraron detalles para esta reserva.</h3>
        <button className="btn btn-secondary mt-5" onClick={handleBack}>
          Volver
        </button>
        </div>
        </div>
      </div>
    );
  }

  return (
    <div className="court container-md">
      <div className="bg-white rounded-4 p-3 my-4">
        <div className="container-fluid">
          <h3 className="my-3 fw-medium">Detalle de Reserva</h3>
          <div className="row">
            <div className="col-12 col-lg-6 mb-5">
              <img src={CourtExample} alt="Placeholder" className="img-fluid h-100" />
            </div>
            <div className="col-12 col-lg-6 mb-5 d-flex flex-column justify-content-start align-items-start">
              <h3 className="w-100 mb-0">{reservaDetalles.recinto_nombre}</h3>
              <span className="w-100 d-block text-start">Ubicación</span>
              <span className="court-image-location fw-medium w-100">{reservaDetalles.comuna}</span>

              <div className="table-responsive my-3 w-100">
                <table className="table table-borderless">
                  <tbody>
                    <tr className="d-flex w-100">
                      <td className="col-6 text-start ps-0">Fecha</td>
                      <td className="col-6 value-text text-end pe-0 fw-medium">
                        {formatDate(reservaDetalles.fecha_hora_inicio)}
                      </td>
                    </tr>
                    <tr className="d-flex w-100">
                      <td className="col-6 text-start ps-0">Estado</td>
                      <td className="col-6 value-text text-end pe-0 fw-medium">
                        {formatEstadoReserva(reservaDetalles.estado_reserva_id)}
                      </td>
                    </tr>
                    <tr className="d-flex w-100">
                      <td className="col-6 text-start ps-0">Horario</td>
                      <td className="col-6 value-text text-end pe-0 fw-medium">
                        {`${formatTime(reservaDetalles.fecha_hora_inicio)} - ${formatTime(reservaDetalles.fecha_termino)} hrs.`}
                      </td>
                    </tr>
                    <tr className="d-flex w-100">
                      <td className="col-6 text-start ps-0">Reserva del 25%</td>
                      <td className="col-6 value-text text-end pe-0 fw-medium">
                        {formatCurrencyCLP(reservaDetalles.valor)}
                      </td>
                    </tr>
                    <tr className="d-flex w-100">
                      <td className="col-6 text-start ps-0">Valor Total</td>
                      <td className="col-6 value-text text-end pe-0 fw-medium">
                        {formatCLP(reservaDetalles.valor_total)}
                      </td>
                    </tr>
                    <tr className="d-flex w-100">
                      <td className="col-6 text-start ps-0">Dirección</td>
                      <td className="col-6 value-text text-end pe-0 fw-medium">
                        {reservaDetalles.direccion}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="container mt-auto">
              <div className="row grid-container-buttons d-flex justify-content-between align-items-stretch">
                <div className="col-12 col-md-6 col-lg-3 mb-3 d-flex align-items-stretch">
                  <button className="btn btn-secondary rounded-5 px-5 py-2 w-100 mt-auto" onClick={handleBack}>
                    Volver
                  </button>
                </div>

                {reservaDetalles.valorada === "1" ? (
                  <div className="col-12 col-md-6 col-lg-3 mb-3 d-flex justify-content-center align-items-center">
                    <StarRating rating={reservaDetalles.valoracion} mode="view" />
                    <span className="ms-2 fw-medium ">{reservaDetalles.valoracion}</span>
                  </div>
                ) : (
                  reservaDetalles.estado_reserva_id === "2" && (
                    <div className="col-12 col-md-6 col-lg-3 mb-3 d-flex align-items-stretch">
                      <button className="btn btn-primary rounded-5 px-5 py-2 w-100 mt-auto" onClick={handleOpenModal}>
                        Calificar
                      </button>
                    </div>
                  )
                )}

                {reservaDetalles.estado_reserva_id === "1" && (
                  <div className="col-12 col-md-6 col-lg-3 mb-3 d-flex align-items-stretch">
                    <button className="btn btn-danger rounded-5 px-5 py-2 w-100 mt-auto" onClick={handleOpenCancelModal}>
                      Cancelar Reserva
                    </button>
                  </div>
                )}

                {reservaDetalles.estado_reserva_id === "4" && (
                  <div className="col-12 col-md-6 col-lg-3 mb-3 d-flex align-items-stretch">
                    <button className="btn btn-warning rounded-5 px-5 py-2 w-100 mt-auto" onClick={handleCheckout}>
                      Ir al Checkout
                    </button>
                  </div>
                )}

                <div className="col-12 col-md-6 col-lg-3 mb-3 d-flex align-items-stretch">
                  <button className="btn btn-success rounded-5 px-5 py-2 w-100 mt-auto" onClick={handleReserveAgain}>
                    Reservar Nuevamente
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalConfirmCancel
        isOpen={isCancelModalOpen}
        onClose={handleCloseCancelModal}
        onConfirm={handleConfirmCancel}
        isLoading={isLoadingCancel}
      />

      <ModalReserva
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        recintoId={reservaDetalles.instalacion_id}
        reservaId={reservaId}
        usuarioId={reservanteId}
        onValoracionSubmit={handleValoracionSubmit}
      />
    </div>
  );
}

export default DetailsBooking;
