import axios from "axios";

const BASE_API_URL = process.env.REACT_APP_BASE_URL;

export const fetchReservasByInstalacionId = async (id) => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=reserva/getMini`, {
      params: { instalacion_id: id },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching reservas:", error);
    return [];
  }
};

export const createReserva = async (reservaData) => {
  try {
    const response = await axios.post(`${BASE_API_URL}?f=reserva/insert`, new URLSearchParams(reservaData).toString(), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating reserva:", error);
    throw error; 
  }
};

export const fetchReservaByIdForCheckout = async (reservaId) => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=reserva/get-checkout&reserva_id=${reservaId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching reserva:", error.response?.data || error.message);
    return null;
  }
};

export const fetchLastReserva = async (reservante_id) => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=reserva/last_reserva`, {
      params: { reservante_id },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching last reserva:", error);
    return null;
  }
};

export const fetchNameInstalacionById = async (instalacion_id) => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=reserva/getInstalacion`, {
      params: { instalacion_id },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching instalacion name:", error);
    return null;
  }
};

/**
 *
 * @param {Number} reservante_id
 * @returns {Promise<Array} Reserva Tabla Mini
 */

export const fetchMiniReservaById = async (reservante_id, page, pageSize) => {
  try {
    console.log(`Fetching mini reserva: reservante_id=${reservante_id}, page=${page}, pageSize=${pageSize}`);
    const response = await axios.get(`${BASE_API_URL}?f=reserva/reservaTablaMini`, {
      params: { reservante_id, page, pageSize },
    });
    console.log("API response data:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching mini reserva:", error);
    return null;
  }
};

/**
 *
 * @param {Number} reservante_id
 * @param {Number} reserva_id
 * @returns {Promise<Object>} Reserva Detalles Tabla
 */
export const fetchReservaDetalles = async (reservante_id, reserva_id) => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=reserva/reservaDetallesTabla`, {
      params: { reservante_id, reserva_id },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching reserva detalles:", error);
    return null;
  }
};

export const fetchAvailableReservations = async (instalacionId, fecha) => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=reserva/getHorasDisponibles`, {
      params: {
        instalacion_id: instalacionId,
        fecha: fecha, // format YYYY-MM-DD
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching available reservations:", error);
    return {};
  }
};

export const updateReserva = async (id, reservaData) => {
  try {
    const response = await axios.post(`${BASE_API_URL}?f=reserva/update`, new URLSearchParams(reservaData).toString(), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: { id }, // Aseguramos que el id se envía correctamente
    });
    return response.data;
  } catch (error) {
    console.error("Error updating reserva:", error.response ? error.response.data : error.message);
    return null;
  }
};

export const fetchHorasReservadas = async (reservante_id) => {
  try {
    const response = await axios.get(`${BASE_API_URL}?f=reserva/countHorasReservadas`, {
      params: { reservante_id },
    });
    return response.data.total_horas;
  } catch (error) {
    console.error("Error fetching horas reservadas:", error);
    return 0;
  }
};

export const cancelReserva = async (id) => {
  try {
    const response = await axios.post(`${BASE_API_URL}?f=reserva/cancel`, new URLSearchParams({ id }).toString(), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error cancelando la reserva:", error);
    throw error;
  }
};
