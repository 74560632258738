import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as MercadoPagoSvg } from "../../assets/icons/payment/mercado-pago.svg";
import "./checkout.css";
import { fetchReservaByIdForCheckout } from "services/reservaApi";
import { createPreference } from "services/payment/mercado-pago";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatCurrencyCLP, formatDateTimeRange } from "utils/format";
import { useUserStore } from "store";

export const Checkout = () => {
  const { id: reservaId } = useParams();
  const [reservationData, setReservationData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("mercadopago");
  const [loading, setLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [paymentLink, setPaymentLink] = useState(null);

  const userName = useUserStore((state) => state.user?.data?.nombre);
  const userEmail = useUserStore((state) => state.user?.data?.email);

  console.log("Datos de reserva:", reservationData);

  useEffect(() => {
    const getReservationDetails = async () => {
      try {
        const response = await fetchReservaByIdForCheckout(reservaId);
        if (response && response.success) {
          setReservationData(response.data);
          setIsPending(response.data.estado_reserva_id === "4");
        } else {
          console.error("Error fetching reservation:", response.message);
        }
      } catch (error) {
        console.error("Error fetching reservation:", error);
      } finally {
        setLoading(false);
      }
    };

    getReservationDetails();
  }, [reservaId]);

  const handlePay = async () => {
    setIsPaying(true);
    setButtonDisabled(true);

    try {
      const preferenceData = {
        title: reservationData.instalacion_nombre || "Reserva",
        quantity: 1,
        unit_price: parseFloat(reservationData.valor) || 0,
        usuario_creacion_id: reservationData.reservante_id,
        usuario_modificacion_id: reservationData.reservante_id,
        reservante_id: reservationData.reservante_id,
        reserve_id: reservationData.id,
        fecha_hora_inicio: reservationData.fecha_hora_inicio,
        fecha_hora_fin: reservationData.fecha_hora_fin,
        payerName: userName,
        payerEmail: userEmail,
      };

      console.log("Datos de la preferencia:", preferenceData);

      const preferenceResponse = await createPreference(preferenceData);

      if (preferenceResponse && preferenceResponse.data && preferenceResponse.data.init_point) {
        const initPointUrl = preferenceResponse.data.init_point;
        setPaymentLink(initPointUrl);
        window.location.href = initPointUrl;
      } else if (preferenceResponse && preferenceResponse.message) {
        toast.error(preferenceResponse.message);
      }
    } catch (error) {
      console.error("Error al crear la preferencia de pago:", error);
      toast.error("Hubo un error al procesar el pago.");
    } finally {
      setIsPaying(false);
      setButtonDisabled(false);
    }
  };
  if (loading) {
    return (
      <div className="container my-4">
        <div className="h-100 d-flex flex-column bg-white shadow-sm rounded-4 p-4">
          <h4 className="mb-4">Checkout</h4>
          <div className="d-flex flex-column flex-grow-1">Cargando...</div>
        </div>
      </div>
    );
  }

  if (!reservationData) {
    return (
      <div className="container my-4">
        <div className="h-100 d-flex flex-column bg-white shadow-sm rounded-4 p-4">
          <h4 className="mb-4">Checkout</h4>
          <div className="d-flex flex-column flex-grow-1">NO HAY DATOS DE RESERVA</div>
        </div>
      </div>
    );
  }

  return (
    <div className="container my-4">
      <ToastContainer />
      <div className="h-100 d-flex flex-column bg-white shadow-sm rounded-4 p-4">
        <h4 className="mb-4">Reserva pendiente de pagar</h4>
        <div className="row">
          {/* Detalles */}
          <div className="col-md-6">
            <p>
              <strong>Recinto:</strong> {reservationData.recinto_nombre || "No hay datos"}
            </p>
            <p>
              <strong>Dirección:</strong> {reservationData.recinto_direccion || "No hay datos"}
            </p>
            <p>
              <strong>Instalación:</strong> {reservationData.instalacion_nombre || "No hay datos"}
            </p>
            <p>
              <strong>Descripción:</strong> {reservationData.instalacion_descripcion || "No hay datos"}
            </p>
            <p>
              <strong>Comuna:</strong> {reservationData.recinto_comuna || "No hay datos"}
            </p>
            <p>
              <strong>Horario:</strong> {formatDateTimeRange(reservationData.fecha_hora_inicio, reservationData.fecha_hora_fin)}
            </p>
            <p>
              <strong>Reservar con el 25% :</strong> {formatCurrencyCLP(reservationData.valor) || "No hay datos"}
            </p>

            <p>
              <strong>Valor:</strong> {formatCurrencyCLP(reservationData.valor_total) || "No hay datos"}
            </p>
          </div>
          {/* Imagen*/}
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <h5>Imagen del recinto</h5>
            {reservationData.recinto_image_url ? <img src={reservationData.recinto_image_url} alt={reservationData.recinto_nombre} className="img-fluid rounded-4" /> : <p>No hay imagen disponible</p>}
          </div>
        </div>

        {/* Metodo pago */}
        <div className="payment-methods mb-4">
          <h5>¿Cómo quieres pagar?</h5>
          <p>Elige una de estas opciones seguras:</p>

          <div className="d-flex justify-content-around">
            <div
              className={`payment-option rounded-4 p-3 ${selectedMethod === "mercadopago" ? "selected" : ""}`}
              onClick={() => setSelectedMethod("mercadopago")}
              style={{
                cursor: "pointer",
                border: "1px solid #e0e0e0",
                width: "45%",
                textAlign: "center",
                position: "relative",
              }}
            >
              {/* Radio button */}
              <div className="form-check" style={{ position: "absolute", top: "10px", left: "10px" }}>
                <input className="form-check-input" type="radio" name="paymentMethod" checked={selectedMethod === "mercadopago"} onChange={() => setSelectedMethod("mercadopago")} style={{ cursor: "pointer" }} />
              </div>
              <MercadoPagoSvg style={{ width: "80px", marginBottom: "10px" }} />
              <p>MercadoPago</p>
            </div>
          </div>
        </div>
        <button className={`rounded-5 p-2 mt-4 ${isPaying ? "btn btn-secondary" : "btn btn-reservar"}`} onClick={handlePay} disabled={buttonDisabled}>
          {isPaying ? "Reservando, por favor espere..." : `Reservar por: ${formatCurrencyCLP(reservationData.valor)}`}
        </button>
        {!isPending && <p className="text-danger mt-2 text-center">No se puede pagar porque el estado no es válido.</p>}
        {/* Enlace alternativo al pago */}
        {paymentLink && (
          <p className="text-center  mt-3">
            Si no has sido redirigido al pago, haz clic{" "}
            <a href={paymentLink} target="_blank" rel="noopener noreferrer" className="text-decoration-underline">
              aquí
            </a>
          </p>
        )}
      </div>
    </div>
  );
};
