export const formatCurrencyCLP = (value) => {
  return new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
    minimumFractionDigits: 0,
  }).format(value);
};

export const formatDateTimeRange = (start, end) => {
  const formattedDate = new Date(start).toLocaleDateString();
  const formattedStartTime = new Date(start).toLocaleTimeString();
  const formattedEndTime = new Date(end).toLocaleTimeString();

  return `${formattedDate} - ${formattedStartTime} a ${formattedEndTime}`;
};
