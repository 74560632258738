import React from "react";
import "./statecourt.css";
import { ReactComponent as Circle } from "assets/icons/circle.svg";

function StateCourt({ customClass, circleClass, textClass, isActive }) {
  return (
    <span
      className={`d-flex justify-content-center align-items-center badge rounded-pill text-light ${customClass} badge2 ${
        isActive ? "ActiveCourt" : "InactiveCourt"
      }`}
    >
      <Circle className={`circle1 ${circleClass}`} />
      <span className={`pill-text fw-bold ${textClass} text-truncate`}>
        {isActive ? "DISPONIBLE" : "NO DISPONIBLE"}
      </span>
    </span>
  );
}

export default StateCourt;
