import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL;

export const getUsuario = async (id) => {
  try {
    const response = await axios.get(`${API_URL}?f=usuario/getPerfil`, {
      params: { id },
    });
    return response.data;
  } catch (error) {
    console.error("Error al obtener los datos del usuario:", error);
    throw error;
  }
};

export const updateUsuario = async (usuario) => {
  try {
    const response = await axios.post(`${API_URL}?f=usuario/update&id=${usuario.id}`, new URLSearchParams(usuario).toString(), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error al actualizar los datos del usuario:", error);
    throw error;
  }
};
