import { Checkout } from "components/checkout/Checkout"
import Footer from "shared/components/footer/footer"
import Navbar from "shared/components/navbar/navbar"

function CheckoutPage() {

  return (
    
    <div className="d-flex flex-column min-vh-100">
    <Navbar />
    <div className='flex-fill bg-image-container'>
      <Checkout/>
    </div>
    <Footer></Footer>
    </div>
  )
}

export default CheckoutPage
