import ForgotPassword from "components/forgot-password/ForgotPassword";
import React from "react";

function ForgotPage() {
  return (
    <div className="bg-image-container min-vh-100">
      <ForgotPassword />
    </div>
  );
}

export default ForgotPage;
