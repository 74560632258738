import React, { useEffect, useState } from "react";
import "./presentation_box.css";
import { ReservationInfo, ReservationInfoSM } from "./ReservationInfo";
import { fetchLastReserva } from "services/reservaApi";
import Stats from "./Stats";
import Skeleton from "@mui/material/Skeleton";
import { useUserStore } from "store";
import { addFavorito, fetchFavoriteReservas, removeFavorito } from "services/favoritoApi";
import { warmUp } from "services/warm-up/warmUp";

const PresentationBox = () => {
  const userId = useUserStore((state) => state.user?.data?.id);
  const userName = useUserStore((state) => state.user?.data?.nombre);
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reloadReservations, setReloadReservations] = useState(false);

  useEffect(() => {
    if (userId) {
      const loadReservations = async () => {
        setLoading(true);
        const data = await fetchLastReserva(userId);
        console.log("data:", data);
        const favoriteData = await fetchFavoriteReservas(userId);
        const favoriteRecintoIds = favoriteData.map(fav => fav.recinto_id);
        console.log('favorite:',favoriteData)
        console.log("data:", data);
        if (Array.isArray(data)) {
          const formattedReservations = data.map((res) => ({
            type: res.tipo_nombre,
            name: res.instalacion_nombre,
            fecha: res.fecha_hora_inicio,
            value: res.valor_total,
            ubi: res.comuna,
            recintoId: res.recinto_id,
            reservaId: res.reserva_id,
            usuarioId: userId,
            valorada: res.valorada,
            valoracion: res.valoracion,
            estado: res.estado_reserva_id,
            isFavorite: favoriteRecintoIds.includes(res.recinto_id),
          }));
          setTimeout(() => {
            setReservations(formattedReservations);
            setLoading(false);
          }, 1000);
        } else {
          setReservations([]);
          setLoading(false);
        }
      };
      loadReservations();
    }
  }, [userId, reloadReservations]);

  // useEffect(() => {
  //   const preventColdStart = async () => {
  //     try {
  //       await warmUp();
  //       console.log("Backend warmed up to prevent cold start.");
  //     } catch (error) {
  //       console.error("Error warming up backend:", error);
  //     }
  //   };
  //   preventColdStart();
  // }, []);

  const handleFavoriteToggle = async (recintoId) => {
    try {
      const isCurrentlyFavorite = reservations.some(
        (res) => res.recintoId === recintoId && res.isFavorite
      );
      
      if (isCurrentlyFavorite) {
        await removeFavorito(userId, recintoId);
      } else {
        await addFavorito(userId, recintoId);
      }

      const updatedReservations = reservations.map((res) => {
        if (res.recintoId === recintoId) {
          return { ...res, isFavorite: !isCurrentlyFavorite };
        }
        return res;
      });
      
      setReservations(updatedReservations);
    } catch (error) {
      console.error("Error al cambiar el estado de favorito:", error);
    }
  };
  
  const handleValoracionSubmit = (reservaId) => {
    setReloadReservations((prev) => !prev);
  };

 return (
    <div className="container-md">
      <div className="presentation-box rounded-4 my-4 p-1 p-md-3">
        <div className="container text-container text-start d-flex flex-column justify-content-center mb-2">
          <div className="d-flex align-items-center mb-0">
            Bienvenido <span className="dest-user ms-2">{userName || "Usuario"}</span>
          </div>
          <p className="stat-parraf mt-0 fw-light">Tus estadísticas son:</p>
        </div>

        <Stats />
        <div className="container w-100 d-grid overflow-x-auto">
          <div className="reserv-title">Últimas reservas</div>
          {loading ? (
            Array.from(new Array(3)).map((_, index) => (
              <Skeleton key={index} variant="rounded" width="100%" height={100} className="rounded-4 mb-3" />
            ))
          ) : reservations.length > 0 ? (
            <table className="table table-responsive align-middle table-separated">
              <tbody className="gap-2 d-hidden md-d-table-row-group">
                {reservations.map((reservation, index) => (
                  <ReservationInfo
                    key={index}
                    {...reservation}
                    onFavoriteToggle={() => handleFavoriteToggle(reservation.recintoId)}
                    onValoracionSubmit={handleValoracionSubmit}
                  />
                ))}
              </tbody>
              <tbody className="gap-2 d-table-row-group md-d-hidden">
                {reservations.map((reservation, index) => (
                  <ReservationInfoSM
                    key={index}
                    {...reservation}
                    onFavoriteToggle={() => handleFavoriteToggle(reservation.recintoId)}
                    onValoracionSubmit={handleValoracionSubmit}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="reserv-title">
              <p>No hay últimas reservas.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PresentationBox;
