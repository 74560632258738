import React, { useEffect, useState } from "react";
import { parseISO } from "date-fns";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import "../court.css";
import { fetchAvailableReservations, createReserva } from "services/reservaApi";
import { fetchInstalacionById } from "services/instalacionApi";
import VanillaCalendar from "./Calendar";
import ReservaModalConfirmation from "./reservaModalConfirmation";
import { useReservationStore } from "store";
import { useNavigate } from "react-router-dom";
import { formatCurrencyCLP } from "utils/format";
import { toast, ToastContainer } from "react-toastify";
import dayjs from "dayjs";

const CourtBooking = ({ selectedInstalacion, selectedInstalacionData, setSelectedInstalacionData, dates, setDates, reservas, setReservas, usuarioId, handleGoBack }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedReservaId, setSelectedReservaId] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [openSuccess, setOpenSuccess] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loadingTimes, setLoadingTimes] = useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);

  const setReservationData = useReservationStore((state) => state.setReservationData);
  const navigate = useNavigate();

  const valorTotal = selectedInstalacionData?.valor_unitario || 0;
  const valorReservaInicial = valorTotal * 0.25;

  console.log("", valorTotal, valorReservaInicial);

  // const handleOpenSuccess = () => setOpenSuccess(true);
  // const handleCloseSuccess = () => {
  //   setOpenSuccess(false);
  //   updateAvailableReservations(selectedDate.selectedDates[0]);
  // };

  const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => setOpenConfirm(false);

  const handleSelectDate = async (date) => {
    setSelectedDate(date);
    setSelectedTime(null);
    setSelectedReservaId(null);

    if (selectedInstalacion && date.selectedDates && date.selectedDates[0]) {
      setLoadingTimes(true);
      await updateAvailableReservations(date.selectedDates[0]);
      setLoadingTimes(false);
    }
  };

  const handleSelectTime = (timeSlot) => {
    setSelectedTime(timeSlot);

    const fechaHoraInicio = parseISO(timeSlot.inicio);
    const fechaHoraFin = parseISO(timeSlot.fin);

    const reservaDisponible = reservas.find((reserva) => {
      const reservaInicio = new Date(reserva.fecha_hora_inicio).getTime();
      const reservaFin = new Date(reserva.fecha_hora_fin).getTime();
      const inicioComparar = fechaHoraInicio.getTime();
      const finComparar = fechaHoraFin.getTime();

      return reservaInicio === inicioComparar && reservaFin === finComparar && reserva.estado_reserva_id === "0";
    });

    if (reservaDisponible) {
      setSelectedReservaId(reservaDisponible.id);
    } else {
      setSelectedReservaId(null);
    }
  };

  const updateAvailableReservations = async (date) => {
    try {
      const parsedDate = parseISO(date);
      const formattedDate = parsedDate.toISOString().split("T")[0]; // format YYYY-MM-DD
      if (selectedInstalacion && formattedDate) {
        const availableReservations = await fetchAvailableReservations(selectedInstalacion, formattedDate);
        const timeSlots = availableReservations[formattedDate] || [];
        setReservas(timeSlots);
        setAvailableTimes(timeSlots);
      }
    } catch (error) {
      console.error("Error updating available reservations:", error);
    }
  };

  const handleReserva = async () => {
    if (!selectedDate || !selectedTime) {
      toast.error("Debe seleccionar una fecha y hora");
      return;
    }

    handleOpenConfirm();
  };

  const confirmReserva = async () => {
    if (!selectedDate || !selectedTime) {
      toast.error("Debe seleccionar una fecha y hora.");
      return;
    }
    console.log("valor reserva inicial", valorReservaInicial);
    console.log("valor total", valorTotal);
    handleCloseConfirm();
    setLoading(true);
    console.log('select time',selectedTime)

    const formattedInicio = dayjs(selectedTime.inicio).set("second", 0).format("YYYY-MM-DD HH:mm:ss");
    const formattedFin = dayjs(selectedTime.fin).set("second", 0).format("YYYY-MM-DD HH:mm:ss");
  

    const reservaData = {
      usuario_creacion_id: usuarioId,
      usuario_modificacion_id: usuarioId,
      reservante_id: usuarioId,
      estado_reserva_id: 4, // pendiente
      instalacion_id: selectedInstalacion,
      fecha_hora_inicio: formattedInicio,
      fecha_hora_fin: formattedFin,
      valor: valorReservaInicial,
      valor_total: valorTotal,
    };

    try {
      const response = await createReserva(reservaData);

      if (response && response.success) {
        const reservaId = response.data.id;

        setReservationData({
          ...reservaData,
          id: reservaId,
          instalacion_nombre: selectedInstalacionData?.nombre,
          instalacion_descripcion: selectedInstalacionData?.descripcion,
        });

        navigate(`/checkout/${reservaId}`);
      } else {
        console.error("Error creating reservation:", response);
        toast.error(response?.message || "No se pudo crear la reserva.");
      }
    } catch (error) {
      console.error("Error creating reservation:", error);
      console.log(error.response.data.message)
      const errorMessage = error.response?.data?.message || "Error inesperado al crear la reserva.";
      console.log(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dates.length > 0) {
      const today = new Date().toISOString().split("T")[0];
      const firstAvailableDate = dates.includes(today) ? today : dates[0];
      setSelectedDate({ selectedDates: [firstAvailableDate] });
      updateAvailableReservations(firstAvailableDate);
    }
  }, [dates, selectedInstalacion]);

  useEffect(() => {
    const fetchInstalacionDetails = async () => {
      try {
        const instalacionData = await fetchInstalacionById(selectedInstalacion);
        setSelectedInstalacionData(instalacionData);
      } catch (error) {
        console.log("Error fetching instalacion details:", error);
      } finally {
        setLoadingSkeleton(false);
      }
    };
    fetchInstalacionDetails();
  }, [selectedInstalacion, setSelectedInstalacionData]);

  return (
    <>
      {loadingSkeleton ? (
        <Skeleton variant="rectangular" width={210} height={118} />
      ) : (
        selectedInstalacionData && (
          <>

            <div className="col-12 my-3">
              <hr className="color-hr" />
              <h4 className="text-start">
                Características de la cancha: <strong>{selectedInstalacionData.nombre}</strong>
              </h4>
            </div>
            <div className="table-responsive">
              <table className="table my-3">
                <thead>
                  <tr className="d-flex">
                    <th scope="col" className="col text-center">
                      Casilleros
                    </th>
                    <th scope="col" className="col text-center">
                      Estacionamiento
                    </th>
                    <th scope="col" className="col text-center">
                      Público
                    </th>
                    <th scope="col" className="col text-center">
                      Camarines
                    </th>

                    <th scope="col" className="col text-center">
                      Valoración
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="d-flex">
                    <td className="col text-center">{selectedInstalacionData.casilleros === "1" ? "Sí" : "No"}</td>
                    <td className="col text-center">{selectedInstalacionData.estacionamiento === "1" ? "Sí" : "No"}</td>
                    <td className="col text-center">{selectedInstalacionData.publico === "1" ? "Sí" : "No"}</td>
                    <td className="col text-center">{selectedInstalacionData.camarines === "1" ? "Sí" : "No"}</td>
                    <td className="col text-center">{selectedInstalacionData.valoracion_promedio}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="row px-0">
              <div className="col-12 text-center">
                <h3 className="my-5">Fechas disponibles</h3>
              </div>
              <div className="col-lg-6 col-md-12 mb-3">
                <div id="dates" className="d-flex justify-content-center align-items-center w-100" style={{ height: "300px" }}>
                  <VanillaCalendar availableDates={dates} onSelectDate={handleSelectDate} />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 mb-3">
                <h3 className="my-3 text-center">Horas disponibles</h3>
                {loadingTimes ? (
                  <div className="container d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                    <CircularProgress className="success-circular-progress" />
                  </div>
                ) : selectedDate ? (
                  availableTimes.length > 0 ? (
                    <div className="button-panel container-fluid px-0 d-flex flex-wrap gap-2 my-2 justify-content-center">
                      {availableTimes.map((timeSlot) => (
                        <button
                        key={timeSlot.inicio}
                        className={`px-5 btn ${selectedTime === timeSlot ? "button-panel-active" : "btn-outline-secondary"}`}
                        style={{ maxWidth: availableTimes.length === 1 ? "50%" : "100%" }}
                        onClick={() => handleSelectTime(timeSlot)}
                        >
                          {timeSlot.inicio.split(" ")[1].slice(0, 5)} - {timeSlot.fin.split(" ")[1].slice(0, 5)}
                        </button>
                      ))}
                    </div>
                  ) : (
                    <div className="nonselected-msg d-flex justify-content-center align-items-center">
                      <p className="text-center">No hay horas disponibles para esta fecha</p>
                    </div>
                  )
                ) : (
                  <div className="nonselected-msg d-flex justify-content-center align-items-center">
                    <p className="text-center">No se ha seleccionado fecha</p>
                  </div>
                )}
              </div>
            </div>
            {selectedTime && (
              <div className="d-flex justify-content-end mt-3 text-end">
                <span className="fs-5">
                  Valor hora: <span className="dest">{formatCurrencyCLP(valorTotal)}</span> <br />
                  Reserva con el 25%: <span className="dest">{formatCurrencyCLP(valorReservaInicial)}</span>
                </span>
              </div>
            )}
            <div className="court-info-reserve mt-3 d-flex justify-content-end gap-3">
              <button className="btn-reservar rounded-5 px-3 px-md-5 py-2" onClick={handleReserva}>
                Reservar
              </button>
              <button className="btn-cancelar rounded-5 px-3 px-md-5 py-2" onClick={handleGoBack}>
                Volver
              </button>
            </div>
          </>
        )
      )}
      <ToastContainer />
      <ReservaModalConfirmation open={openConfirm} handleClose={handleCloseConfirm} handleConfirm={confirmReserva} />
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CourtBooking;
