import React, { useState } from "react";
import "./forgot.css";
import loginLogo from "assets/images/courapp-login.png";
import "@fontsource/poppins";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";
import { forgotPassword } from "services/authApi";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email) {
      toast.error("Por favor, ingresa tu correo electrónico.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await forgotPassword(email); // Usa el servicio
      console.log(response);
      toast.success("Correo enviado correctamente. Revisa tu bandeja de entrada.");
      navigate("/");
    } catch (error) {
      toast.error(error || "Error al intentar recuperar la contraseña.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container min-vh-100 d-flex justify-content-center align-items-center">
      <div className="container container-register">
        <div className="row">
          <div className="col-lg-12 text-center">
            <img src={loginLogo} alt="Logo" className="login-logo mb-3" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card px-4 py-4">
              <h4 className="text-center mb-3">Recuperar Contraseña</h4>
              <p className="text-center text-muted mb-2">Ingresa tu correo electrónico y te enviaremos un correo con tu contraseña.</p>

              <div className="text-start my-2">
                <button className="btn text-decoration-underline text-muted mb-2" onClick={() => navigate("/")}>
                  Volver
                </button>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <TextField id="email" className="form-input w-100" type="email" value={email} onChange={(e) => setEmail(e.target.value)} label="Correo Electrónico" variant="outlined" required disabled={isLoading} />
                </div>
                <button type="submit" className="btn btn-primary w-100 mb-3" disabled={isLoading}>
                  {isLoading ? "Enviando..." : "Recuperar Contraseña"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
