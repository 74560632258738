import React, { useMemo } from 'react';
import { Map, Marker } from '@vis.gl/react-google-maps';

const MapComponent = ({ geo_x, geo_y }) => {
  // check if the coordinate is valid
  const isValidCoordinate = (coord) => !isNaN(parseFloat(coord)) && isFinite(coord);

  // default center for the map
  const defaultCenter = useMemo(() => ({
    lat: -36.827909,
    lng: -73.051539,
  }), []);

  // coordinates for the marker
  const markerPosition = useMemo(() => {
    if (isValidCoordinate(geo_x) && isValidCoordinate(geo_y)) {
      return {
        lat: parseFloat(geo_y),
        lng: parseFloat(geo_x),
      };
    }
    return null; // no marker if coordinates are invalid
  }, [geo_x, geo_y]);


  //TODO change marker to advancedmarker
  return (
    <Map
       defaultZoom={15}
       center={markerPosition || defaultCenter}
       mapId="cbe108b482e65e9c"
    >
       {markerPosition && <Marker position={markerPosition} />} 
    </Map>
  );
};

export default MapComponent;
