import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};

const reservaModalConfirmation = ({ open, handleClose, handleConfirm }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-confirmation-title"
      aria-describedby="modal-confirmation-description"
    >
      <Box sx={style}>
        <Typography id="modal-confirmation-title" variant="h6" component="h2">
         ¿Estás seguro?
        </Typography>
        <Typography id="modal-confirmation-description" sx={{ mt: 2 }}>
          ¿Estás seguro de reservar e ir a pagar?
        </Typography>
        <div className="court-info-reserve mt-3 d-flex justify-content-between gap-3">
          <button className="btn-reservar rounded-5 px-5  py-2" onClick={handleConfirm} > Aceptar </button>
          <button className="btn-cancelar rounded-5 px-5 py-2" onClick={handleClose} > Cancelar </button>
        </div>
      </Box>
    </Modal>
  );
};

export default reservaModalConfirmation;
