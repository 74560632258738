import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "App";
import { GoogleOAuthProvider } from '@react-oauth/google';


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
     <GoogleOAuthProvider clientId="185508495455-tl8nr3j2bq3ebcs9rpcjphirf557gt0k.apps.googleusercontent.com">
      <App />
     </GoogleOAuthProvider>
  </React.StrictMode>
);
