import React, {useEffect, useState} from "react";
import "./search.scss";
import "react-datepicker/dist/react-datepicker.css";
import {useNavigate} from "react-router-dom";
import {Autocomplete, CircularProgress, FormControl, TextField} from "@mui/material";
import {fetchComunasRecintos} from "services/recintoApi";
import {fetchTiposInstalacion} from "services/tipoInstalacionApi"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StarIcon from '@mui/icons-material/Star';
import comunasJson from "../../assets/json/comunas.json";
import searchBg from "../../assets/images/search_background.png";
import searchBgMobile from "../../assets/images/search_background_rotated.png";

const Search = () => {
  const [comunas, setComunas] = useState([]);
  const [selectedComuna, setSelectedComuna] = useState("");
  const [error, setError] = useState(false);
  const [tiposInstalacion, setTiposInstalacion] = useState([]);
  const [selectedTipo, setSelectedTipo] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const deg2rad = () => Math.PI / 180;

  const calculateDistanceInKM = (lat1, lon1, lat2, lon2) => {
    console.time('calculateDistanceInKM')
    const R = 6371; // Radius of the earth in km
    const deg2radConst = deg2rad();
    const dLat = deg2radConst * (lat2 - lat1);
    const dLon = deg2radConst * (lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2radConst * lat1) * Math.cos(deg2radConst * lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2); // a = sin²(Δφ/2) + cos φ1 ⋅ cos φ2 ⋅ sin²(Δλ/2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); // c = 2 ⋅ atan2( √a, √(1−a) )
    const distanceInKM = R * c;
    // return rounded to 2 decimal places
    console.timeEnd('calculateDistanceInKM')
    return Math.round(distanceInKM * 100) / 100;
  }

  const handleAutocompleteOpen = async () => {
    if (comunas.length === 0) {
      setLoading(true);
      const comunas = await fetchComunasRecintos();
      const comunaNames = comunas.map((comuna) => comuna.comuna.toUpperCase());

      setComunas(comunaNames);

      navigator.geolocation.getCurrentPosition(function (position) {
        const currentLocation = { lat: +position.coords.latitude, lng: +position.coords.longitude };

        const menor = comunasJson.reduce((prev, curr) => {
          if (comunaNames.indexOf(curr.name.toUpperCase()) === -1) return prev;

          const prevDist = Math.sqrt(Math.pow(currentLocation.lat - parseFloat(prev.lat), 2) + Math.pow(currentLocation.lng - parseFloat(prev.lng), 2));
          const currDist = Math.sqrt(Math.pow(currentLocation.lat - parseFloat(curr.lat), 2) + Math.pow(currentLocation.lng - parseFloat(curr.lng), 2));

          return prevDist < currDist ? prev : curr;
        }, comunasJson[0]);

        console.log('Menor distancia:', menor.name, 'Distancia:', Math.sqrt(Math.pow(currentLocation.lat - parseFloat(menor.lat), 2) + Math.pow(currentLocation.lng - parseFloat(menor.lng), 2)));

        console.log('Distancia entre ubicación y comuna seleccionada:', calculateDistanceInKM(currentLocation.lat, currentLocation.lng, parseFloat(menor.lat), parseFloat(menor.lng)), 'km');
        setSelectedComuna(menor.name.toUpperCase());
      });

      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedComuna || !selectedTipo) {
      setError(true);
    } else {
      setError(false);
      navigate(`/places?comuna=${selectedComuna}&tipo_id=${selectedTipo.id}`);
    }
  };

  useEffect(() => {
    const loadTiposInstalacion = async () => {
      const tipos = await fetchTiposInstalacion();
      setTiposInstalacion(tipos);
    };

    loadTiposInstalacion();
  }, []);

  const handleTipoChange  = (event, newValue) => {
    event.preventDefault();
    setSelectedTipo(newValue);
    console.log("valoracion", setSelectedTipo);
  };

  useEffect(() => {
    handleAutocompleteOpen();
  }, []);

  return (
    <div className="search_page ">
      <div className="search_container position-relative">
        <picture>
          <source media="(min-width: 768px)" srcSet={searchBg}/>
          <img src={searchBgMobile} alt="" className="search_container-image"/>
        </picture>
        <div className="search-title container-md fw-bolder">
          Busca en <span className="dest" >300+</span> canchas disponibles a lo largo de Chile
        </div>
        <div className="container-md d-flex rounded-4 justify-content-center ">
          <div className="container-md search_space mx-2 mb-5 p-4 rounded-4">
            <div className="container-fluid px-0">
              <form className="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-5 col-md-12 d-flex align-items-center my-2">
                    <LocationOnIcon className="me-2" />
                    <Autocomplete
                      className="w-100"
                      disablePortal
                      id="combo-box-comunas"
                      options={comunas}
                      value={selectedComuna}
                      noOptionsText="No hay comunas"
                      onOpen={handleAutocompleteOpen}
                      onChange={(event, newValue) => setSelectedComuna(newValue)}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Comunas"
                          error={error && !selectedComuna}
                          helperText={error && !selectedComuna ? "Selecciona una comuna" : ""}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-lg-5 col-md-12 d-flex align-items-center my-2">
                    <StarIcon className="me-2" />
                    <FormControl className="w-100">
                      <Autocomplete
                        options={tiposInstalacion}
                        getOptionLabel={(option) => option.nombre}
                        renderInput={(params) => <TextField {...params} label="Tipo de Deporte" />}
                        onChange={handleTipoChange}
                        value={selectedTipo}
                      />
                    </FormControl>
                  </div>
                  <div className="col-lg-2 col-md-12 d-flex align-items-center my-2">
                    <button type="submit" className="search-button w-100 h-100 rounded-5 p-3 ">
                      BUSCAR
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
