import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Ex from "assets/icons/example.png";
import HeartIcon from "@mui/icons-material/Favorite";
import HeartBorderIcon from "@mui/icons-material/FavoriteBorder";
import ArrowRight from "@mui/icons-material/ArrowForwardIos";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import ValueIcon from "@mui/icons-material/AttachMoney";
import LocationIcon from "@mui/icons-material/LocationOn";
import StarRating from "components/court/star";
import ModalReserva from "components/modals/modalsReserva";
import { Tooltip } from "@mui/material";

const formatCLP = (value) => {
  return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
};

const formatFecha = (fecha) => {
  return fecha.substring(0, 10);
};

export const ReservationInfo = ({ type, name, fecha, value, ubi, recintoId, reservaId, usuarioId, valorada, valoracion, onValoracionSubmit, estado, isFavorite, onFavoriteToggle }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <tr className="bg-white">
      <td className="">
        <img src={Ex} alt="" className="reservation-info-img rounded-3" />
      </td>
      <td className="">
        <div className="d-flex flex-column">
          <span className="reservation-info-title fw-semibold text-nowrap">{type}</span>
          <span className="reservation-info-name">{name}</span>
        </div>
      </td>
      <td>
        <div className="d-flex flex-column flex-xl-row align-items-center gap-3">
          <div className="calendario-container rounded-5 p-2 d-flex justify-content-center align-items-center">
            <CalendarIcon />
          </div>
          <div className="d-flex flex-column text-center text-xl-start">
            <span className="fw-semibold text-nowrap">{formatFecha(fecha)}</span>
            <span className="text-muted subtitle-tabla text-nowrap">Fecha de Arriendo</span>
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex flex-column flex-xl-row align-items-center gap-3 border-x px-3">
          <div className="valor-container rounded-5 p-2 d-flex justify-content-center align-items-center">
            <ValueIcon />
          </div>
          <div className="d-flex flex-column text-center text-xl-start">
            <span className="fw-semibold text-nowrap ">{formatCLP(value || 0)}</span>
            <span className="text-muted subtitle-tabla text-nowrap">Valor de Arriendo total</span>
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex flex-column flex-xl-row align-items-center gap-3">
          <div className="ubicacion-container rounded-5 p-2 d-flex justify-content-center align-items-center">
            <LocationIcon />
          </div>
          <div className="d-flex flex-column text-center text-xl-start">
            <span className="fw-semibold">{ubi}</span>
            <span className="text-muted subtitle-tabla">Ubicación</span>
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex justify-content-center">
          {valorada !== "1" && estado === 2 ? (
            <button className="btn-purple border-0 p-2 rounded-5" onClick={handleOpenModal}>
              Calificar
            </button>
          ) : (
            <div className=" ">
              <StarRating rating={valoracion} mode="single" />
            </div>
          )}
        </div>
      </td>
      <td>
        <div className="d-flex justify-content-center">
          <Tooltip title="Me gusta el Recinto">
            <button className="btn-heart border-0 p-3 rounded-5" onClick={onFavoriteToggle}>
              {isFavorite ? <HeartIcon style={{ color: "red" }} /> : <HeartBorderIcon />}
            </button>
          </Tooltip>
        </div>
      </td>
      <td>
        <div className="d-flex justify-content-center">
          <Link to={`/detail-booking?id=${reservaId}`}>
            <button className="btn-arrow border-0 p-3 rounded-5">
              <ArrowRight />
            </button>
          </Link>
        </div>
      </td>
      <ModalReserva isOpen={isModalOpen} onClose={handleCloseModal} recintoId={recintoId} reservaId={reservaId} usuarioId={usuarioId} onValoracionSubmit={onValoracionSubmit} />
    </tr>
  );
};

export const ReservationInfoSM = ({ img, type, name, fecha, value, ubi, recintoId, reservaId, usuarioId, valorada, onValoracionSubmit, estado, isFavorite, onFavoriteToggle }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <tr>
      {/*<td className="">*/}
      {/*  <img src={Ex} alt="" className="reservation-info-img rounded-circle"/>*/}
      {/*</td>*/}
      <td className="p-0">
        <div className="d-flex flex-column">
          <span className="reservation-info-name text-break py-3 fw-semibold">{name}</span>
          <div className="d-flex flex-row justify-content-center py-3">
            <div className="d-flex flex-column flex-xl-row align-items-center gap-1 px-3">
              <div className="calendario-container rounded-5 p-2 d-flex justify-content-center align-items-center">
                <CalendarIcon />
              </div>
              <div className="d-flex flex-column text-center text-xl-start">
                <span className="fw-semibold text-nowrap">{formatFecha(fecha)}</span>
                <span className="text-muted subtitle-tabla text-nowrap">Fecha de Arriendo</span>
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row align-items-center gap-1 px-3 border-x">
              <div className="valor-container rounded-5 p-2 d-flex justify-content-center align-items-center">
                <ValueIcon />
              </div>
              <div className="d-flex flex-column text-center text-xl-start">
                <span className="fw-semibold text-nowrap ">{formatCLP(value || 0)}</span>
                <span className="text-muted subtitle-tabla text-nowrap">Valor de Arriendo total</span>
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row align-items-center gap-1 px-3">
              <div className="ubicacion-container rounded-5 p-2 d-flex justify-content-center align-items-center">
                <LocationIcon />
              </div>
              <div className="d-flex flex-column text-center text-xl-start">
                <span className="fw-semibold">{ubi}</span>
                <span className="text-muted subtitle-tabla">Ubicación</span>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row gap-4 justify-content-center py-3 border-top">
            <div className="d-flex justify-content-center">
              {valorada !== "1" && estado === 2 ? (
                <button className="btn-purple border-0 p-2 rounded-5" onClick={handleOpenModal}>
                  Calificar
                </button>
              ) : (
                <div className="button-placeholder"></div>
              )}
            </div>
            <button className="btn-heart border-0 p-3 rounded-5" onClick={onFavoriteToggle}>
              {isFavorite ? <HeartIcon style={{ color: "red" }} /> : <HeartBorderIcon />}
            </button>
            <Link to={`/detail-booking?id=${reservaId}`}>
              <button className="btn-arrow border-0 p-3 rounded-5">
                <ArrowRight />
              </button>
            </Link>
          </div>
        </div>
      </td>
      <ModalReserva isOpen={isModalOpen} onClose={handleCloseModal} recintoId={recintoId} reservaId={reservaId} usuarioId={usuarioId} onValoracionSubmit={onValoracionSubmit} />
    </tr>
  );
};
