import React from "react";
import "./home.css";

import Footer from "shared/components/footer/footer";
import Navbar from "shared/components/navbar/navbar";
import PresentationBox from "components/home/presentation_box";

const Home = () => {
    return (
        <div className="d-flex flex-column min-vh-100">
            <Navbar />
            <div className="flex-fill bg-image-container">
                <PresentationBox />
            </div>
            <Footer />
        </div>
    );
};

export default Home;
