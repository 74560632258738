import React from 'react'
import NavBar from "../../shared/components/navbar/navbar"
import Profile from "../../components/profile/profile"
import Footer from "../../shared/components/footer/footer"

function ProfilePage() {

  return (
    
    <div className="d-flex flex-column min-vh-100">
    <NavBar />
    <div className='flex-fill bg-image-container'>
      <Profile></Profile>
    </div>
    <Footer></Footer>
    </div>
  )
}

export default ProfilePage