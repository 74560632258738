import React from 'react';
import Courts from '../../components/court/court';
import NavBar from '../../shared/components/navbar/navbar';
import Footer from '../../shared/components/footer/footer';

function CourtPage() {
  return (

    <div className="d-flex flex-column min-vh-100">
      <NavBar />
      <div className='flex-fill bg-image-container'>
        <Courts />
      </div>
      <Footer />
    </div>
  );
}

export default CourtPage;
