import React from 'react'
import Search from 'components/search/search'
import Navbar from 'shared/components/navbar/navbar';
import Footer from 'shared/components/footer/footer';

function searchPage() {
  return (
    <>
      <Navbar />
      <div className='bg-image-container'>
        <Search></Search>
      </div>
      <Footer></Footer>
    </>
  )
}

export default searchPage