import React, { useEffect, useState } from 'react';
import './places.css';
import PlaceInfo from './placeInfo';
import { fetchRecintoByParamComunaTipo } from 'services/recintoApi';
import { useSearchParams } from 'react-router-dom';
import { Button, Skeleton } from '@mui/material';

const Places = () => {
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    has_next_page: false,
    has_previous_page: false,
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const pageParam = parseInt(searchParams.get('page')) || 1;

  useEffect(() => {
    const loadRecintos = async () => {
      setLoading(true);
      const comuna = searchParams.get('comuna');
      const tipo_id = searchParams.get('tipo_id');
      const page = pageParam;

      const response = await fetchRecintoByParamComunaTipo(comuna, tipo_id, page);
      if (response) {
        const { data, total_pages, has_next_page, has_previous_page } = response;

        const formattedPlaces = data.map((recinto) => ({
          id: recinto.id,
          name: recinto.nombre,
          location: recinto.direccion,
          note: recinto.valoracion_promedio,
          webPage: recinto.url,
          email: recinto.email,
          phone: recinto.telefono,
          images: recinto.image_url,
          totalInstalaciones: recinto.total_instalaciones,
        }));

        setPlaces(formattedPlaces);
        setPagination({
          total_pages,
          has_next_page,
          has_previous_page,
        });
      } else {
        setPlaces([]);
        setPagination({
          total_pages: 1,
          has_next_page: false,
          has_previous_page: false,
        });
      }
      setLoading(false);
    };

    loadRecintos();
  }, [searchParams]);

  const handleNextPage = () => {
    if (pagination.has_next_page) {
      const newPage = pageParam + 1;
      searchParams.set('page', newPage);
      setSearchParams(searchParams);
    }
  };

  const handlePreviousPage = () => {
    if (pagination.has_previous_page) {
      const newPage = pageParam - 1;
      searchParams.set('page', newPage);
      setSearchParams(searchParams);
    }
  };

  return (
    <div className="places container-md h-100">
      <div className="places-container rounded-4 p-2 p-md-3 my-4">
        {loading ? (
          <div className="row row-cols-1 row-cols-lg-3 g-3">
            {Array.from(new Array(3)).map((_, index) => (
              <div className="col" key={index}>
                <Skeleton variant="rectangular" width="100%" height={600} className="rounded-4" />
              </div>
            ))}
          </div>
        ) : places.length > 0 ? (
          <>
            <div className="row row-cols-1 row-cols-lg-3 g-3">
              {places.map((place, index) => (
                <div className="col" key={index}>
                  <PlaceInfo
                    id={place.id}
                    images={place.images}
                    name={place.name}
                    location={place.location}
                    note={place.note}
                    webPage={place.webPage}
                    email={place.email}
                    phone={place.phone}
                    totalInstalaciones={place.totalInstalaciones} 
                  />
                </div>
              ))}
            </div>
            <div className="pagination-controls d-flex justify-content-center mt-4">
              <Button variant="contained" onClick={ handlePreviousPage } color='success' disabled={ !pagination.has_previous_page }>
                Anterior
              </Button>
              <span className="mx-3 align-self-center">
                Página { pageParam } de { pagination.total_pages }
              </span>
              <Button variant="contained" onClick={ handleNextPage } color='success' disabled={ !pagination.has_next_page }>
                Siguiente
              </Button>
            </div>
          </>
        ) : (
          <div className="container-md text-center my-5 h-100">
            <p>No se encontraron resultados.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Places;
