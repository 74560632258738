import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

// export const useUserStore = create((set) => ({
//   user: undefined,

//   setUser: (user) => set({ user }),

//   logout: () => set({ user: undefined }),
// }))

export const useUserStore = create(
  devtools((set) => ({
    user: undefined,

    setUser: (user) => set({ user }),

    updateUser: (updatedFields) =>
      set((state) => ({
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            ...updatedFields,
          },
        },
      })),

    logout: () => set({ user: undefined }),
  }))
);

