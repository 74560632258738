import Reservations from 'components/reservations/reservations'
import React from 'react'
import Navbar from 'shared/components/navbar/navbar';
import Footer from 'shared/components/footer/footer';


function ReservationsPage() {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar />
      <div className='flex-fill bg-image-container'>
        <div className='container-md'>
          <Reservations></Reservations>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default ReservationsPage